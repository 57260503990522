import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagedialogDialog } from '../../../components/imagedialog/imagedialog.dialog';
import { HelperService } from '../../../services/helper.service';
import { JSettings } from '../../../services/supa.service';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { BrakeAnswer, VhcBrakeModule } from '../vhcbrakemodule';
import { brakediscpad } from '../../../interfaces/brakediscpad.interface';
import { MediaService } from '../../../services/media.service';

@Component({
  selector: 'app-brakeanswer',
  templateUrl: './brakeanswer.dialog.html',
  styleUrls: ['./brakeanswer.dialog.scss'],
})
export class BrakeanswerDialog implements OnInit {

  @Input('readonly') readonly = false;
  @Input('brakemodule') brakemodule: VhcBrakeModule;
  @Input('settings') settings: JSettings;
  @Input('brakediscpads') brakediscpads: brakediscpad[] = [];

  selectedbrake = 0

  async openRecommendations() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Select a predefined recommendation',
      buttons: [...this.brakemodule.recommendations.map((answ, i) => {
        return {
          text: answ[0],
          role: i.toString()
        }
      }), {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel'
      }]
    });

    await actionSheet.present();
    let { role } = await actionSheet.onDidDismiss();
    if (!(role == "backdrop" || role == "cancel")) {
      
      const idx = Number.parseInt(role);
      this.brakemodule.data.brakes[this.selectedbrake].recommendation = this.brakemodule.recommendations[idx][0]
      this.brakemodule.data.brakes[this.selectedbrake].recommendation2 = this.brakemodule.recommendations[idx][1]
    }
  }

  // copyValues(brake: BrakeAnswer) {
  //   let cid = 0 // this is when the selected id is 1
  //   if (this.selectedbrake == 0) {
  //     cid = 1
  //   } else if (this.selectedbrake == 2) {
  //     cid = 3
  //   } else if (this.selectedbrake == 3) {
  //     cid = 2
  //   }
  //   this.brakemodule.data.brakes[cid].discpadid = brake.discpadid
  //   this.setAnswer(this.brakemodule.data.brakes[cid])
  //  
  // }
  copyValues(brake: BrakeAnswer) {
    let cid = 0 // this is when the selected id is 1
    if (this.selectedbrake == 0) {
      cid = 1
    } else {
      cid = 0
    } 
    this.brakemodule.data.brakes[cid].discpadid = brake.discpadid
    this.setAnswer(this.brakemodule.data.brakes[cid])
   
  }
  getText(id) {
    if (id) {
      const res = this.brakediscpads.find(t => t.id == id)
      if (res) {
        return res.name
        
      }
    }
    return 'Unknown set'
  }

  constructor(private helper: HelperService, private modalController: ModalController, public platform: Platform, 
    private actionSheetController: ActionSheetController, private androidPermissions: AndroidPermissions, private media: MediaService, private photoViewer: PhotoViewer) { }

  ngOnInit() {
    if (!(this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p)))) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      );
    }
  }

  dismissModal() {
    this.modalController.dismiss(false, 'cancel');
  }
  
  nextModal() {
    this.modalController.dismiss(true);
  }

  helpcontent() {
    this.helper.openLightBox(['/assets/iqblue-exploded-brakes.svg'])
  }

  brakeDetails(id) {
    if (id == undefined) {
      return "-"
    }
    const b = this.brakediscpads.find(b => b.id == id);
    if (b) {
      return "Discs: red = " + b.discredwidthmm + "mm, amber = " + b.discamberwidthmm + "mm. Pads: " + b.padredwidthmm + "mm, amber = " + b.padamberwidthmm + "mm"
    }
    return "This set does not exist anymore"
  }

  async addPicture() {
    const picture = await this.media.takePicture()
    if (picture == 'rejected') {
      return;
    }
    this.brakemodule.data.brakes[this.selectedbrake].images.push(picture);
  }

  async openPicture(imgstr) {
    if (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) {
      //Desktop
      let { data } = await this.helper.createAndOpenModal(ImagedialogDialog, {
        Url: imgstr
      }, 'doubled-modal auto-height-image');
    } else {
      // android / ios
      this.photoViewer.show(imgstr);
    }
  }

  @ConfirmDelete()
  deleteImg(i) {
    this.brakemodule.data.brakes[this.selectedbrake].images.splice(i,1)
  }

  setAnswer(brake:BrakeAnswer) {
    const brakeIdx = this.brakediscpads.findIndex(b => b.id == brake.discpadid)
    if (brakeIdx < 0) return;
    if (this.brakemodule.data.brakes[this.selectedbrake].discmm == null) return;
    if (this.brakemodule.data.brakes[this.selectedbrake].padmm == null) return;
    if (this.brakemodule.data.brakes[this.selectedbrake].discmm <= this.brakediscpads[brakeIdx].discredwidthmm) {           // RED DISC
      this.brakemodule.data.brakes[this.selectedbrake].value = 1
    } else if (this.brakemodule.data.brakes[this.selectedbrake].padmm <= this.brakediscpads[brakeIdx].padredwidthmm) {     // RED PAD
      this.brakemodule.data.brakes[this.selectedbrake].value = 1
    } else if (this.brakemodule.data.brakes[this.selectedbrake].discmm <= this.brakediscpads[brakeIdx].discamberwidthmm) {  // AMBER DISC
      this.brakemodule.data.brakes[this.selectedbrake].value = 2
    } else if (this.brakemodule.data.brakes[this.selectedbrake].padmm <= this.brakediscpads[brakeIdx].padamberwidthmm) {   // AMBER PAD
      this.brakemodule.data.brakes[this.selectedbrake].value = 2
    } else {
      this.brakemodule.data.brakes[this.selectedbrake].value = 3
    }
  }
}