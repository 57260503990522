import { _ra } from "../../functions";
import { HelperService } from "../../services/helper.service";
import { SupaService } from "../../services/supa.service";
import { Vhcmodule } from "../vhcmodule";
import { SiganswerDialog } from "./siganswer/siganswer.dialog";

export class VhcSignatureModule extends Vhcmodule
{
    value=""

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = "Signature";
        this.name = "Signature"
    }

    getPdfVersion(param:any=undefined,primarylang=false) {
        return {
            table: {
                widths: ['*', 180],
                body: [[primarylang ? this.name : _ra(this.name2), { text: '', margin: [0, 50, 0, 0], fillColor: '#deefde' }]],
            },
            margin: [0, 10, 0, 10],
            layout: 'noBorders'
        }
    }

    public getIcon() :string {
        return this.value.length > 0 ? 'checkbox-outline' : 'square-outline'
    }

    async openAnswer(param:any=undefined) {
        await this.loadSettings();

        let {data} = await this.helper.createAndOpenModal(SiganswerDialog, {
            settings: this.settings,
            stext: this.name,
            signature: this.value
        }, 'doubled-modal');

        console.log(data);
        if (data) {
            this.value = data.resultimage;
            return data.nextmodal;
        }
        return false
    }

    public toDatabase(includedata=false) {
        if (includedata) {
            return {name: this.name, name2: this.name2, value: this.value, type: this.type, dateAuthorised: this.dateAuthorised}
        } else {
            return {name: this.name, name2: this.name2, type: this.type, dateAuthorised: this.dateAuthorised}
        }
    }
    
    public fromDatabase(obj, includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.dateAuthorised = obj.dateAuthorised
        if (includedata) {
            if (obj.hasOwnProperty('value')) {
                this.value = obj.value
            }
        }
    }

    getHtmlVersion(param:any=undefined,primarylang=false) {
        return `
        <div class="row">
            <span class="col">${primarylang ? this.name : this.name2}</span>
            <textarea class="col" value="${this.value}"></textarea>
        </div>
        `;
    }
}

export interface VhcSignaturePDF {
    date: string
}