import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {

  lpfilter = ''
  vinfilter = ''
  namefilter = ''
  vehicles: any[] | null | undefined = undefined
  managevehicles = false

  constructor(private supa: SupaService, private helper: HelperService, private router: Router) { }

  ngOnInit() {
    this.managevehicles = (this.supa.LoginObject.value?.permissions.includes('VhcVehiclesManage') == true)
    this.init();
  }

  async openVehicle(vin: string) {
    this.router.navigate(['/vehicles/editor', {vin: vin}]);
  }

  async init() {
    let filters:string[] = []
    if (this.lpfilter != '')
      filters.push('licenseplate.ilike.%'+this.lpfilter+'%')
    if (this.vinfilter != '')
      filters.push('vin.ilike.%'+this.vinfilter+'%')
    if (this.namefilter != '')
      filters.push('drivername.ilike.%'+this.namefilter+'%,ownername.ilike.%'+this.namefilter+'%')

    if (filters.length == 0) {
      let { data } = await this.supa.supabase.from('vehicle').select('vin,licenseplate,cartype').limit(20)
      this.vehicles = data
    } else {
      let { data } = await this.supa.supabase.from('vehicle').select('vin,licenseplate,cartype').or(filters.join(',')).limit(20)
      this.vehicles = data
    }
  }
  
  async onAdd() {
    let {data} = await this.helper.presentNewEditDialog(
      'Add new vehicle', [
        { name: 'vin', label: 'VIN', value: '', type: 'text'},
        { name: 'licenseplate', label: 'License plate', value: '', type: 'text' },
        { name: 'cartype', label: 'Car Type', value: '', type: 'text' },
        { name: 'enginecode', label: 'Enginecode', value: '', type: 'text' },
        { name: 'yearofbuild', label: 'Year of build', value: '', type: 'text' },
        { name: 'communicatewithowner', label: 'Communicate with owner', value: false, type: 'boolean' },
        { name: 'drivername', label: 'Driver name', value: '', type: 'text' },
        { name: 'drivermail', label: 'Driver mail', value: '', type: 'text' },
        { name: 'driverphone', label: 'Driver phone', value: '', type: 'text' },
        { name: 'ownername', label: 'Owner name', value: '', type: 'text' },
        { name: 'ownermail', label: 'Owner mail', value: '', type: 'text' },
        { name: 'ownerphone', label: 'Owner phone', value: '', type: 'text' }
    ])
    if (data) {
      if ((data.data.vin??'').length !== 17) {
        data.data.vin = data.data.vin.substring(0,17).padStart(17, '.');
      }
    }
    if (data) {
      await this.supa.supabase.from('vehicle').insert(data.data);
      this.init();
    }
  }

}
