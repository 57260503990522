import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

// pdf
import * as pdfMake from "pdfmake/build/pdfmake";
import { pdfMake_vfs, pdfmake_fonts } from '../../../../vfs_fonts';
(<any>pdfMake).vfs = pdfMake_vfs;
(<any>pdfMake).fonts = pdfmake_fonts;
// end pdf

import { ModuleoverviewDialog } from '../../../../components/moduleoverview/moduleoverview.dialog';
import { HelperService } from '../../../../services/helper.service';
import { JSettings, SupaService } from '../../../../services/supa.service';
import { Vhcmodule } from '../../../../vhcmodules/vhcmodule';
import { ConfirmDelete } from '../../../../decorators/confirmdelete.decorator';
import { vhcblueprint } from '../../../../interfaces/vhcblueprint.interface';
import { VhcModuleFactory } from '../../../../vhcmodules/vhcmoduleimport';

@Component({
  selector: 'app-vhcblueprinteditor',
  templateUrl: './vhcblueprinteditor.component.html',
  styleUrls: ['./vhcblueprinteditor.component.scss']
})
export class VhcblueprinteditorComponent implements OnInit {

  loadingDone = undefined
  qId = undefined
  qParent = undefined

  name = ''
  disclaimer = ''
  disclaimer2 = ''
  description = ''

  selectedItem='Pre'

  includeprecheck = true
  includemultipointinspection = true
  includequalityinspection = true

  premodules: Vhcmodule[] = []
  mpimodules: Vhcmodule[] = []
  qimodules: Vhcmodule[] = []

  fixedansweringorder = true
  includepre = true
  includempi = true
  includejp = true
  includeauth = true
  includeqi = true
  naming:any;

  settings: JSettings = undefined;

  constructor(
        private helper: HelperService, 
        private supa: SupaService, 
        private route: ActivatedRoute, 
        private router: Router,
        private modalController: ModalController) {
  }

  onBack() {
    this.router.navigate(['/settings/vhcblueprints/'])
  }

  async onDelete() {
    if(!this.qId) {
      this.helper.notify("This has never been saved yet", "warning");
      return
    }
    let { data, error } = await this.supa.supabase.from('vhcblueprint').delete().eq('id', this.qId);
    if (error) {
      this.helper.notify("Could not delete the item", "danger");
      return;
    }
    this.router.navigate(['/settings/vhcblueprints/'])
  }

  async onSave() {
    let newData: vhcblueprint = {
      name: this.name,
      description: this.description,
      disclaimer: this.disclaimer,
      disclaimer2: this.disclaimer2,
      fixedansweringorder: this.fixedansweringorder,
      includepre: this.includepre,
      includempi: this.includempi,
      includejp: this.includejp,
      includeauth: this.includeauth,
      includeqi: this.includeqi,
      premodules: this.premodules.map(vhcmod => vhcmod.toDatabase()),
      mpimodules: this.mpimodules.map(vhcmod => vhcmod.toDatabase()),
      qimodules: this.qimodules.map(vhcmod => vhcmod.toDatabase()),
      naming:this.naming
    }
    if (this.qId) {
      let { data, error } = await this.supa.supabase.from('vhcblueprint').update(newData).eq('id', this.qId);
      if (error) {
        this.helper.notify("Could not save the item", "danger");
        return;
      }
    } else {
      newData["parent"] = this.qParent;

      let { data, error } = await this.supa.supabase.from('vhcblueprint').insert(newData).single()
      if (error) {
        this.helper.notify("Could not save the item", "danger");
        return;
      }
      this.qId = data.id
    }
    this.helper.promptYesNo('Blueprint was saved.', 'Dow you want to leave the editor?', () => {
      this.router.navigate(['/settings/vhcblueprints/'])
    }, 'Leave editor', 'Keep editing')
  }

  async addModule(step = 0) {
    const modal = await this.modalController.create({
      component: ModuleoverviewDialog,
      componentProps: {hidemaintainance: step != 1 || this.mpimodules.findIndex(m => m.type == 'Maintenance') >= 0},
      cssClass: 'fixed-modal'
    });
    await modal.present();
    let {data} = await modal.onDidDismiss();
    if (data) {
      let newModule = VhcModuleFactory(this.helper, this.supa, data)
      await newModule.openEditor();
      if (step == 0) {
        this.premodules.push(newModule)
      } else if (step == 1) {
        this.mpimodules.push(newModule)
      } else {
        this.qimodules.push(newModule)
      }
    }
  }

  @ConfirmDelete()
  deleteModule(step, index) {
    if (step == 0) {
      this.premodules.splice(index, 1);
    } else if (step == 1) {
      this.mpimodules.splice(index, 1);
    } else {
      this.qimodules.splice(index, 1);
    }
  }

  async init() {
    this.settings = await this.supa.Settings();
    this.route.params.subscribe(params => {
      if (Object.keys(params).includes('id')) {
        this.qId = params.id;
        this.supa.supabase.from('vhcblueprint').select('*').eq('id', params.id).single().then(v => {
          if (v.error) {
            console.log(v.error);
            this.helper.notify('Could not load this blueprint', 'danger')
            this.router.navigate(['settings/vhcblueprints'])  
          } else {
            this.name = v.data.name,
            this.description = v.data.description,
            this.disclaimer = v.data.disclaimer,
            this.disclaimer2 = v.data.disclaimer2,
            this.fixedansweringorder = v.data.fixedansweringorder,
            this.includepre = v.data.includepre,
            this.includempi = v.data.includempi,
            this.includejp = v.data.includejp,
            this.includeauth = v.data.includeauth,
            this.includeqi = v.data.includeqi,
            this.premodules = v.data.premodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true)),
            this.mpimodules = v.data.mpimodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true)),
            this.qimodules = v.data.qimodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true)),
            this.loadingDone = true;
            this.naming = v.data.naming
          }
        }, e => {
          console.log(e);
          this.helper.notify('Could not load this blueprint', 'danger')
          this.router.navigate(['settings/vhcblueprints'])
        })
      //new blueprint
      } else if (Object.keys(params).includes('parent')) {
        this.qParent = params.parent == 'null' ? null : Number.parseInt(params.parent)
        this.naming={
          "Pre":{
            "name":"Pre check",
            "abbreviation":"PRE"
          },
          "MPI":{
            "name":"Multi point inspection",
            "abbreviation":"MPI"
          },
          "QI":{
            "name":"Quality inspection",
            "abbreviation":"QI"
          }
        }
        this.loadingDone = true;
      } else {
        this.router.navigate(['settings/vhcblueprints'])
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  isEditing = false
  async doOpen(module: Vhcmodule) {
    this.isEditing = true;
    await module.openEditor();
    this.isEditing = false;
  }

  doReorderPre(ev: any) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.premodules = ev.detail.complete(this.premodules);
  }
  
  doReorderMpi(ev: any) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.mpimodules = ev.detail.complete(this.mpimodules);
  }

  doReorderQi(ev: any) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.qimodules = ev.detail.complete(this.qimodules);
  }

  downloadPdf() {
    const pdfDocGenerator = pdfMake.createPdf(this.generatePdf());
    pdfDocGenerator.open();
  }

  generatePdf() {
    const noBorder = [false, false, false, false]

    let content = [
      { text: this.name, style: 'header' },
      { text: this.description, margin: [0,0,0,15] }
    ]

    if (this.includeprecheck) {
      content.push({text: this.naming.Pre.name, style: 'subheader'})
      this.premodules.forEach((mod:Vhcmodule) => {
        let pdfrepr = mod.getPdfVersion(null, true)
        if (pdfrepr != null) {
          content.push(pdfrepr);
        }
      })
    }
    if (this.includemultipointinspection) {
      content.push({text: this.naming.MPI.name, style: 'subheader'})
      this.mpimodules.forEach((mod:Vhcmodule) => {
        let pdfrepr = mod.getPdfVersion(null, true)
        if (pdfrepr != null) {
          content.push(pdfrepr);
        }
      })
    }
    if (this.includequalityinspection) {
      content.push({text: this.naming.QI.name, style: 'subheader'})
      this.qimodules.forEach((mod:Vhcmodule) => {
        let pdfrepr = mod.getPdfVersion(null, true)
        if (pdfrepr != null) {
          content.push(pdfrepr);
        }
      })
    }

    const docDefinition = {
      pageSize: 'A4',
      content: content,
      defaultStyle: {
        font: 'ubuntu',
        fontSize: 10
      },
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [ 0, 0, 0, 20 ]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [ 0, 0, 0, 20 ]
        }
      }
    }
    return docDefinition
  }

}