import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { JSettings } from '../../../services/supa.service';
import SignaturePad from 'signature_pad';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-siganswer',
  templateUrl: './siganswer.dialog.html',
  styleUrls: ['./siganswer.dialog.scss'],
})
export class SiganswerDialog implements AfterViewInit, OnInit {

  @Input('signature') signature: string;
  @Input('stext') stext: string;
  @Input('settings') settings: JSettings;

  @ViewChild('canvas', { static: true }) signaturePadElement;

  signaturePad: SignaturePad;

  constructor(private modalController: ModalController, private helper: HelperService) { }

  dismissModal() {
    console.log(this.signaturePadElement);
    this.modalController.dismiss({nextmodal: false, resultimage: this.signaturePad.toDataURL()}, 'cancel');
  }
  
  nextModal() {
    this.modalController.dismiss({nextmodal: true, resultimage: this.signaturePad.toDataURL()});
  }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    this.resizeCanvas();
    
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.clear();

    const ctx = this.signaturePadElement.nativeElement.getContext("2d")
    ctx.fillStyle = '#f3f3f3';
    ctx.fillRect(0,0, 375, 250)
    
    if (this.signature !== "") {
      this.signaturePad.fromDataURL(this.signature);
    }
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.signaturePadElement.nativeElement.width = 375 * ratio;
    this.signaturePadElement.nativeElement.height = 250 * ratio;
    this.signaturePadElement.nativeElement.style = `width:375px; height:250px`;
    this.signaturePadElement.nativeElement.getContext("2d").scale(ratio, ratio);
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
      const ctx = this.signaturePadElement.nativeElement.getContext("2d")
      ctx.fillStyle = '#f3f3f3';
      ctx.fillRect(0,0, 375, 250)
    }
  }

}
