/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable quote-props */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DmsCreateJobcardResultValue, DmsService, DmsTask } from '../../../services/dms.service';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';
import { VhcBrakeModule } from '../../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcQuestionblockModule } from '../../../vhcmodules/questionblock/vhcquestionblockmodule';
import { VhcTyreModule } from '../../../vhcmodules/tyremodule/vhctyremodule';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';
import { Part } from 'src/app/interfaces/part.interface';
import { DmsDragDropDialog } from './dmsdragdrop/dmsdragdrop.dialog';
import { VhcCross_sellModule } from 'src/app/vhcmodules/cross_sellmodule/cross_sellmodule';
import { DmsItemsPairDialog, dmsvhccombi } from './itemspair/dmsitemspair.dialog';

@Component({
  selector: 'app-jobpartscreen',
  templateUrl: './jobpartscreen.dialog.html',
  styleUrls: ['./jobpartscreen.dialog.scss'],
})
export class JobPartscreenDialog implements OnInit {

  @Input('title') title: Vhcmodule[]
  @Input('completed') completed: string
  @Input('modules') modules: Vhcmodule[]
  @Input('vhcrecord') vhcrecord: any
  @Input('vehicle') vehicle: any
  @Input('ispre') ispre = false
  @Input('ignorePartsDMSImport') ignorePartsDMSImport: string[] = []

  settings: JSettings = undefined
  exconf: any = undefined

  constructor(private modalController: ModalController, private helper: HelperService, private supa: SupaService, private loadingController: LoadingController, private dms:DmsService) {
  }

  getModules(forDms=false) {
    return this.modules.filter((module:Vhcmodule) => {
      if (module.type == 'Questionblock') {
        return (module as VhcQuestionblockModule).questions.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Tyres') {
        return (module as VhcTyreModule).data.tyres.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Brakes') {
        return (module as VhcBrakeModule).data.brakes.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Cross_sell' && !forDms) {
        return (module as VhcCross_sellModule).data.length > 0
      } else {
        return false
      }
    });
  }

  // async receiveDMS() {
  //   let requisitionitems: ReqTask[] = []
  //   let requisitionparts: ReqPart[] = []

  //   let jobcarddata:DmsCreateJobcardResultValue = this.vhcrecord.organisationdata.prepostresult.response.find(r => r.job_details == "R");
  //   if(jobcarddata) {
      
  //   } else {
  //     this.helper.notify('No repair order found..')
  //     return
  //   }
    
  //   // retrieve the jobs
  //   let res: any = await this.dms.receiveDMS_MPI_ITEMS(jobcarddata.job_no_new);
  //   if (res) {
  //     if (Array.isArray(res.items)) {
  //       requisitionitems.push(...res.items)
  //     }
  //   }
  //   console.log(requisitionitems);
    
  //   // retrieve the parts
  //   res = await this.dms.receiveDMS_MPI_PARTS(jobcarddata.job_no_new);
  //   if (res) {
  //     if (Array.isArray(res.items)) {
  //       requisitionparts.push(...res.items)
  //     }
  //   }
  //   console.log(requisitionparts);
    
  //   // process the items
  //   let vhcitems = JobPartscreenDialog.ConvertToItems(this.getModules());
  //   let _combined: dmsvhccombi[] = []
  //   requisitionitems.forEach(r => {
  //     const idx = _combined.findIndex(v => v.concern_id == r.concern_id);
  //     if (idx < 0) {
  //         _combined.push({
  //             concern_id: r.concern_id,
  //             concern_name: r.concern_desc,
  //             item_id: r.iq_concern_id
  //         });
  //     }
  //   })
  //   requisitionparts.forEach(r => {
  //     const idx = _combined.findIndex(v => v.concern_id == r.concern_id);
  //     if (idx < 0) {
  //         _combined.push({
  //             concern_id: r.concern_id,
  //             concern_name: r.jr_req_part,
  //             item_id: r.iq_concern_id
  //         });
  //     }
  //   })
  //   // vhcitems.forEach(v => {
  //   //   const idx = _combined.findIndex(c => c.concern_id == v.ItemID);
  //   //   if (idx >= 0) {
  //   //       _combined[idx].item_id = v.ItemID
  //   //   }
  //   // })

  //   let data = await this.helper.createAndOpenModal(DmsItemsPairDialog, {
  //     vhcitems: vhcitems,
  //     combineditems: _combined
  //   }, 'doubled-modal big-modal')
  //   if (data) {
  //     // User has selected the appropriate items, apply the result for items found
  //     console.log(_combined);
  //     let jobcleared_itemids = []
  //     let partcleared_itemids = []
  //     _combined.filter(c => c.item_id != '').forEach(c => {
  //       const jobitems = requisitionitems.filter(r => r.concern_id == c.concern_id);
  //       const partitems = requisitionparts.filter(r => r.concern_id == c.concern_id);

  //       // find the real item to set jobs
  //       this.modules.forEach((module, moduleindex) => {
  //         if (module.type == 'Questionblock') {
  //           const dataindex = (module as VhcQuestionblockModule).questions.findIndex(v => v.portalid == c.item_id);
  //           if (dataindex >= 0) {
  //             if (!jobcleared_itemids.includes(dataindex)) {
  //               jobcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].questions[dataindex].jobs = []
  //             }
  //             this.modules[moduleindex].questions[dataindex].jobs.push(...jobitems.map(reqj => {
  //               return {
  //                 name: reqj.ji_operation_descr,
  //                 name2: reqj.ara_ji_operation_descr,
  //                 code: reqj.ji_operation_id,
  //                 price: (reqj.labor_price ? Number.parseFloat(reqj.labor_price) : 0.0),
  //                 labourtax: 0.15,
  //                 hour: reqj.std_time
  //               }
  //             }))
  //             if (!partcleared_itemids.includes(dataindex)) {
  //               partcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].questions[dataindex].parts = []
  //             }
  //             this.modules[moduleindex].questions[dataindex].parts.push(...partitems.map(reqp => {
  //               return {
  //                 name: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 name2: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 code: (reqp.qt_part ? reqp.qt_part : reqp.parts_req_id),
  //                 price: reqp.net_amount,
  //                 parttax: 0.15
  //               }
  //             }))
  //             this.calculatePrice(this.modules[moduleindex].questions[dataindex])
  //           }
  //         } else if (module.type == 'Tyres') {
  //           const dataindex = (module as VhcTyreModule).data.tyres.findIndex(v => v.portalid == c.item_id);
  //           if (dataindex >= 0) {
  //             if (!jobcleared_itemids.includes(dataindex)) {
  //               jobcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].data.tyres[dataindex].jobs = []
  //             }
  //             this.modules[moduleindex].data.tyres[dataindex].jobs.push(...jobitems.map(reqj => {
  //               return {
  //                 name: reqj.ji_operation_descr,
  //                 name2: reqj.ara_ji_operation_descr,
  //                 code: reqj.ji_operation_id,
  //                 price: (reqj.labor_price ? Number.parseFloat(reqj.labor_price) : 0.0),
  //                 labourtax: 0.15,
  //                 hour: reqj.std_time
  //               }
  //             }))
  //             if (!partcleared_itemids.includes(dataindex)) {
  //               partcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].data.tyres[dataindex].parts = []
  //             }
  //             this.modules[moduleindex].data.tyres[dataindex].parts.push(...partitems.map(reqp => {
  //               return {
  //                 name: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 name2: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 code: (reqp.qt_part ? reqp.qt_part : reqp.parts_req_id),
  //                 price: reqp.net_amount,
  //                 parttax: 0.15
  //               }
  //             }))
  //             this.calculatePrice(this.modules[moduleindex].data.tyres[dataindex])
  //           }
  //         } else if (module.type == 'Brakes') {
  //           const dataindex = (module as VhcBrakeModule).data.brakes.findIndex(v => v.portalid == c.item_id);
  //           if (dataindex >= 0) {
  //             if (!jobcleared_itemids.includes(dataindex)) {
  //               jobcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].data.brakes[dataindex].jobs = []
  //             }
  //             this.modules[moduleindex].data.brakes[dataindex].jobs.push(...jobitems.map(reqj => {
  //               return {
  //                 name: reqj.ji_operation_descr,
  //                 name2: reqj.ara_ji_operation_descr,
  //                 code: reqj.ji_operation_id,
  //                 price: (reqj.labor_price ? Number.parseFloat(reqj.labor_price) : 0.0),
  //                 labourtax: 0.15,
  //                 hour: reqj.std_time
  //               }
  //             }))
  //             if (!partcleared_itemids.includes(dataindex)) {
  //               partcleared_itemids.push(dataindex)
  //               this.modules[moduleindex].data.brakes[dataindex].parts = []
  //             }
  //             this.modules[moduleindex].data.brakes[dataindex].parts.push(...partitems.map(reqp => {
  //               return {
  //                 name: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 name2: reqp.quote_qty + 'x - ' + reqp.jr_req_part,
  //                 code: (reqp.qt_part ? reqp.qt_part : reqp.parts_req_id),
  //                 price: reqp.net_amount,
  //                 parttax: 0.15
  //               }
  //             }))
  //             this.calculatePrice(this.modules[moduleindex].data.brakes[dataindex])
  //           }
  //         }
  //       })
  //     })
  //   }
  // }

  sanitiseItem(item) {
    if (!item.jobs) {
      item.jobs = []
    }
    if (!item.parts) {
      item.parts = []
    }
    if (!item.sundries) {
      item.sundries = 0
    }
  }

  calculatePrice(item) {
    this.sanitiseItem(item)
    item.price = Number.parseFloat('' + item.sundries) + Number.parseFloat(item.parts.map(p => p.price).reduce((a, b) => a + b, 0)) + Number.parseFloat(item.jobs.map(j => j.price).reduce((a, b) => a + b, 0));
  }

  public static ConvertToItems(modules): DmsTask[] {
    let repairitems: DmsTask[] = [];
    modules.forEach(m => {
      if (m.type == 'Questionblock') {
        m.questions.forEach(q => {
          if (q.value == 2 || q.value == 1 || q.dateAuthorised) {
            repairitems.push({ "ItemName": q.name, "Remarks1": q.recommendation, "Remarks2": q.recommendation2, "Color": q.value == 1 ? 'red' : 'amber', "ItemID": q.portalid })
          }
        })
      } else if (m.type == 'Tyres') {
        m.data.tyres.forEach(t => {
          if (t.value == 2 || t.value == 1 || t.dateAuthorised) {
            repairitems.push({ "ItemName": 'Tyre, ' + (t.name + ' -> ' + t.size + ' ' + t.loadindex), "Remarks1": t.recommendation, "Remarks2": t.recommendation2, "Color": t.value == 1 ? 'red' : 'amber', "ItemID": t.portalid })
          }
        })
      } else if (m.type == 'Brakes') {
        m.data.brakes.forEach(t => {
          if (t.value == 2 || t.value == 1 || t.dateAuthorised) {
            repairitems.push({ "ItemName": 'Brake, ' + t.name, "Remarks1": t.recommendation, "Remarks2": t.recommendation2, "Color": t.value == 1 ? 'red' : 'amber', "ItemID": t.portalid })
          }
        })
      } else if (m.type == 'Cross_sell') {
        m.data.forEach(t => {
          if (t.value == 2 || t.value == 1 || t.dateAuthorised) {
            repairitems.push({ "ItemName": t.name, "Remarks1": "", "Remarks2": "", "Color": 'amber', "ItemID": t.portalid })
          }
        })
      }
    // eslint-disable-next-line @typescript-eslint/semi
    })
    return repairitems.map(r => {r.parts = []; return r;});
  }

  // async sendDMS() {
  //   if (this.vhcrecord.organisationdata?.prepostresult?.response?.length >= 1) {
  //     let jobcarddata:DmsCreateJobcardResultValue = this.vhcrecord.organisationdata.prepostresult.response.find(r => r.job_details == "R");
  //     if(jobcarddata) {
  //       let result = await this.dms.sendDMS_MPI_ITEMS(this.vehicle.vin, jobcarddata.master_job_no, jobcarddata.job_no_new, this.vhcrecord.id, this.getModules(true));
  //       if (result?.response?.length >= 1) {
  //         if (result.response[0].status == "200") {
  //           this.vhcrecord.organisationdata.requestedmpiitems = true;
  //           await this.updateRecord({organisationdata: this.vhcrecord.organisationdata});
  //         } else {
  //           this.helper.notify('Could not successfully send the items to the DMS..', 'danger')
  //         }
  //       }
  //     }
  //     // else { no repair order found, so go on and make it}
  //   } else {
  //     // no organisation data found, so go on and make it
  //     this.vhcrecord.organisationdata.prepostresult.response = []
  //   }
  //   let creationresult = await this.dms.sendDMS_PRE_ITEMS(JobPartscreenDialog.ConvertToItems(this.getModules(true)), this.vhcrecord, this.vehicle)
  //   this.vhcrecord.organisationdata.prepostresult.response.push(...creationresult.response)
  //   this.vhcrecord.organisationdata.requestedmpiitems = true;
  //   await this.updateRecord({organisationdata: this.vhcrecord.organisationdata});
  // }

  async updateRecord(update_data) {
    // Loading modal
    const loading = await this.loadingController.create({
      message: 'Uploading the vhc record to the database, one moment please',
      spinner: 'bubbles'
    });
    await loading.present();
    // Upload the data and return the result.
    let { data, error } = await this.supa.supabase.from('vhcrecord').update(update_data, { returning: 'minimal' }).eq('id', this.vhcrecord.id)
    if (error) {
      this.helper.notify('Could not upload data', 'danger');
      loading.dismiss();
      return false;
    } else {
      this.helper.notify('Data was uploaded', 'success');
      loading.dismiss();
      return true;
    }
  }

  filteredTyres(tyres) {
    return tyres.filter(v => v.value == 2 || v.value == 1 || v.dateAuthorised)
  }

  filteredBrakes(brakes) {
    return brakes.filter(v => v.value == 2 || v.value == 1 || v.dateAuthorised)
  }

  ngOnInit() {
    this.loadSettings();
    console.log(this.vhcrecord.organisationdata);
  }

  async loadSettings() {
    this.settings = await this.supa.Settings();
    this.exconf = await this.supa.ExternalConfig();
  }

  async KeyLoopGetJC() {
    const l = await this.helper.getLoader();
    l.message = 'Getting repair-order from Keyloop';
    await l.present();

    const vhcitems = JobPartscreenDialog.ConvertToItems(this.getModules(false));
    console.log(vhcitems);

    const ro = await this.supa.supabase.rpc('get_jobcard_kl', {jc_id: this.vhcrecord.iqjc_id});
    console.log(ro);
    let parts: Part[] = [];
    (ro?.data as any).jobs?.forEach(job => {
      job.parts?.forEach(part => {
        parts.push({
          code: part?.partCode,
          name: part?.description,
          name2: part?.description,
          price: part?.listPrice?.netValue
        })
      })
    })
    console.log(parts, this.ignorePartsDMSImport);
    l.dismiss();

    let removecount = 0;
    this.ignorePartsDMSImport.forEach(pname => {
      const delindex = parts.findIndex(p => p.name === pname);
      if (delindex >= 0) {
        parts.splice(delindex, 1)
        removecount++;
      }
    })
    console.log('Removed ' + removecount + ` parts because they were in use at the ${this.vhcrecord.naming.Pre.name}`);

    const res = await this.helper.createAndOpenModal(DmsDragDropDialog, {leftItems: vhcitems, rightItems: parts}, 'big-modal doubled-modal')
    console.log(res);

    if (res?.data?.leftItems) {
      res?.data?.leftItems.forEach(dmsitem => {
        for (let i = 0; i < this.modules.length; i++) {
          if (this.modules[i].type == 'Questionblock') {
            const qi = (this.modules[i] as VhcQuestionblockModule).questions.find(v => v.portalid === dmsitem.ItemID)
            if (Array.isArray(qi?.parts)) {
              qi.parts.push(...dmsitem.parts)
              continue;
            } else if (qi) {
              qi.parts = dmsitem.parts
              continue;
            }
          } else if (this.modules[i].type == 'Tyres') {
            const ti = (this.modules[i] as VhcTyreModule).data.tyres.find(v => v.portalid === dmsitem.ItemID)
            if (Array.isArray(ti?.parts)) {
              ti.parts.push(...dmsitem.parts)
              continue;
            } else if (ti) {
              ti.parts = dmsitem.parts
              continue;
            }
          } else if (this.modules[i].type == 'Brakes') {
            const bi = (this.modules[i] as VhcBrakeModule).data.brakes.find(v => v.portalid === dmsitem.ItemID)
            if (Array.isArray(bi?.parts)) {
              bi.parts.push(...dmsitem.parts)
              continue;
            } else if (bi) {
              bi.parts = dmsitem.parts
              continue;
            }
          } else if (this.modules[i].type == 'Cross_sell') {
            const ci = (this.modules[i] as VhcCross_sellModule).data.find(v => v.portalid === dmsitem.ItemID)
            if (Array.isArray(ci?.parts)) {
              ci.parts.push(...dmsitem.parts)
              continue;
            } else if (ci) {
              ci.parts = dmsitem.parts
              continue;
            }
          }
        }
      });

      console.log('done');
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  saveModal() {
    this.modalController.dismiss({savedata: true});
  }

  complete() {
    this.helper.promptYesNo('Do you want to complete "' + this.title + '"?', 'The step will be finished and the next step will unlock.', ()=>{
      this.modalController.dismiss({completestep: true});
    }, 'Complete step', 'Cancel')
  }

}

export interface ReqTask {
  jm_master_job_no: string;
  ji_job_no: string;
  concern_id: string;
  concern_desc: string;
  ji_srl_no: string;
  ji_operation_id: string;
  ji_operation_descr: string;
  ara_ji_operation_descr: string;
  labor_price?: any;
  std_time: number;
  iq_concern_id?: string;
  est_approved: string;
}

export interface ReqPart {
  jm_master_job_no: string; // Master job card number.
  job_no: string;           // Job card number.
  parts_req_id: number;     // parts request id.
  concern_id: string;       // job card concern id.
  sr_no: number;            // serial number.
  jr_req_part: string;      // Part Name.
  requested_qty: number;    // Requested Qty.
  quote_qty: number;        // Quoted Qty.
  net_amount: number;       // Part Amount.
  est_approved: string;     // Approval Flag.
  qt_part?: string;
  iq_concern_id?: string;
}
