/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/semi */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, AlertController, AlertInput, LoadingController, ModalController, Platform } from '@ionic/angular';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { Tyrebrand } from '../../interfaces/tyrebrand.interface';
import { Vehicle } from '../../interfaces/vehicle.interface';
import { vhcrecord, VHCSTATUS } from '../../interfaces/vhcrecord.interface';
import { HelperService } from '../../services/helper.service';
import { IQTask, JSettings, SupaService } from '../../services/supa.service';
import { VHCQuestion, VhcQuestionblockModule } from '../../vhcmodules/questionblock/vhcquestionblockmodule';
import { Vhcmodule } from '../../vhcmodules/vhcmodule';
import { VhcModuleFactory } from '../../vhcmodules/vhcmoduleimport';
import { MaintenancechooseDialog } from './maintenancechoose/maintenancechoose.dialog';
import { MpiscreenDialog } from './mpiscreen/mpiscreen.dialog';
import { PrescreenDialog } from './prescreen/prescreen.dialog';

// pdf
import * as pdfMake from "pdfmake/build/pdfmake";
import { pdfMake_vfs, pdfmake_fonts } from '../../vfs_fonts';
import { _ra } from "../../functions";
import { QiscreenDialog } from './qiscreen/qiscreen.dialog';
import { AuthscreenDialog } from './authscreen/authscreen.dialog';
import { JobPartscreenDialog } from './jobpartscreen/jobpartscreen.dialog';
import { brakediscpad } from '../../interfaces/brakediscpad.interface';
import { jlocation_name_code } from '../../interfaces/jlocation.interface';
import { VhcMaintenancedModule } from '../../vhcmodules/maintenancemodule/maintenancemodule';
import { FollowupchooseDialog } from '../../components/followupchoosedialog/followupchoosedialog.component';
import { DmsCar, DmsCarApiResult, DmsCreateJobcardResult, DmsService, DmsTask } from '../../services/dms.service';
import { UserSearchDialog } from '../../components/usersearchdialog/usersearch.component';
import { VhcMessage } from '../messages/messages.component';
import { MediaService } from '../../services/media.service';
import { IframeWrapperComponent } from '../../components/iframewrapper/iframewrapper.component';
import { VhcBrakeModule } from '../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcMediaModule } from '../../vhcmodules/mediamodule/mediamodule';
import { VhcTyreModule } from '../../vhcmodules/tyremodule/vhctyremodule';
(<any>pdfMake).vfs = pdfMake_vfs;
(<any>pdfMake).fonts = pdfmake_fonts;
// end pdf

import { decode } from 'base64-arraybuffer'
import { SpeachService } from '../../services/speach.service';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { DmsDragDropDialog } from './jobpartscreen/dmsdragdrop/dmsdragdrop.dialog';
import { Part } from 'src/app/interfaces/part.interface';

@Component({
  selector: 'app-vhcrecord',
  templateUrl: './vhcrecord.component.html',
  styleUrls: ['./vhcrecord.component.scss'],
})
export class VhcrecordComponent implements OnInit, OnDestroy {

  jlocations: jlocation_name_code[] = undefined
  vhcrecord: vhcrecord = undefined
  vehicle: Vehicle = undefined
  tyrebrands: Tyrebrand[] = undefined
  brakediscpads: brakediscpad[] = undefined
  premodules: Vhcmodule[] = []
  mpimodules: Vhcmodule[] = []
  qimodules: Vhcmodule[] = []

  iqtools = false

  canmanagelocation = false

  currentLocation = ""

  COPYOVERNAME = `Items copied from the pre check (diagnose)`

  settings = this.supa.Settings();

  constructor(private supa: SupaService,
    private route: ActivatedRoute,
    private platform: Platform,
    private router: Router,
    private helper: HelperService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private dms: DmsService,
    private media: MediaService,
    private speech: SpeachService,
    private alertController: AlertController) {
  }

  async saveIQTools() {
    await this.updateRecordWithObj({
      status: this.vhcrecord.status,
      completedpre: this.vhcrecord.completedpre?.length === 0 ? null : this.vhcrecord.completedpre,
      completedprejp: this.vhcrecord.completedprejp?.length === 0 ? null : this.vhcrecord.completedprejp,
      completedpreauth: this.vhcrecord.completedpreauth?.length === 0 ? null : this.vhcrecord.completedpreauth,
      completedmpi: this.vhcrecord.completedmpi?.length === 0 ? null : this.vhcrecord.completedmpi,
      completedjp: this.vhcrecord.completedjp?.length === 0 ? null : this.vhcrecord.completedjp,
      completedauth: this.vhcrecord.completedauth?.length === 0 ? null : this.vhcrecord.completedauth,
      completedqi: this.vhcrecord.completedqi?.length === 0 ? null : this.vhcrecord.completedqi,
      iqjc_id: this.vhcrecord.iqjc_id
    })
  }

  openCurrent() {
    switch (this.vhcrecord.status) {
      case "new":
        break;
      case "pre":
        this.openPre();
        break;
      case "mpi":
        this.openMpi();
        break;
      case "jp":
        if (this.vhcrecord.completedmpi) {
          this.openJP();
        } else {
          this.openPreJP();
        }
        break;
      case "auth":
        this.openAuth(!this.vhcrecord.completedpreauth);
        break;
      case "qi":
        this.openQi();
        break;
      case "fin":
        this.finalizeRecord()
        break;
      default:
        break;
    }
  }

  async setMileage() {
    let dmsmilege = 0;
    if((await this.settings).externalconfig === 2 && false) { //@Jeroen: false weghalen.
      let vehicleresult: DmsCarApiResult = await this.dms.getDMS_VEHICLE(this.vehicle.vin);
      if (vehicleresult.items.length == 0) {
        this.helper.notify('Vehicle is not in the DMS', 'danger');
        return false;
      }
      dmsmilege = vehicleresult.items[0].last_service_km;
    }
    this.helper.promptInput('Set mileage', 'Please enter the mileage', this.vhcrecord.mileage, async (v)=>{
      let d = await this.updateRecordWithObj({mileage: v});
      console.log(d);
      if (d) {
        this.helper.notify('The mileage was updated', 'success')
        this.vhcrecord.mileage = v;
        await this.supa.supabase.rpc('update_milage', {vin: this.vhcrecord.vin, milage: v});
      } else {
        this.helper.notify('Could not update the mileage', 'danger')
      }
    }, '', 'number', 'Save', 'Cancel')
  }

  async setHandover() {

    const DATEFORMATHANDOVER = 'yyyy/MM/dd HH:mm';

    let res:DateTime = await this.helper.chooseDateTime({
      IsRange: false,
      WithTime: true,
      SingleDate: this.vhcrecord.handover ? DateTime.fromFormat(this.vhcrecord.handover, DATEFORMATHANDOVER) : DateTime.now()
    }) as DateTime;

    const newhandover = res.toFormat(DATEFORMATHANDOVER);

    if (newhandover !== this.vhcrecord.handover) {
      let d = await this.updateRecordWithObj({handover: newhandover});
      console.log(d);
      if (d) {
        this.helper.notify('The handover was updated', 'success')
        this.vhcrecord.handover = newhandover;
        
        if (this.vhcrecord.organisationdata?.prepostresult?.response?.length > 0) {
          let jobcardids = []
          this.vhcrecord.organisationdata.prepostresult.response.forEach(r => {
            if (r.master_job_no || r.job_no_new) {
              jobcardids.push(r)
            }
          })
          // gebruik de jobcardids array.
        }
      } else {
        this.helper.notify('Could not update the handover', 'danger')
      }
    }
  }


  // async getVehicle():Promise<boolean> {
  //   let vehicleresult: DmsCarApiResult = await this.dms.getDMS_VEHICLE(this.vehicle.vin);
  //   if (vehicleresult.items.length == 0) {
  //     this.helper.notify('Vehicle is not in the DMS', 'danger');
  //     return false;
  //   }
  //   this.vehicle.yearofbuild = Number.parseInt(vehicleresult.items[0].model_year);
  //   this.vehicle.enginecode = vehicleresult.items[0].model;

  //   const loading = await this.loadingController.create({
  //     message: 'Updating the vehicle',
  //     spinner: 'bubbles',
  //     backdropDismiss: true
  //   });
  //   await loading.present();
  //   let vehicleUpdateResult = await this.supa.supabase.from('vehicle').update({yearofbuild: this.vehicle.yearofbuild, enginecode: this.vehicle.enginecode}, { returning: 'minimal' }).eq('vin', this.vehicle.vin)
  //   if (vehicleUpdateResult.error) {
  //     console.log(vehicleUpdateResult.error);
  //     this.helper.notify('Vehicle could not be updated', 'danger');
  //     await loading.dismiss();
  //     return false;
  //   }
  //   await loading.dismiss();
  //   return true;
  // }

  collectCopyToMPI(): VhcQuestionblockModule {
    let collection = new VhcQuestionblockModule(this.helper, this.supa);
    collection.name = this.COPYOVERNAME;
    collection.name2 = this.COPYOVERNAME
    this.premodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((m, mi) => {
          if ((this.premodules[i].questions[mi].value == 1 || this.premodules[i].questions[mi].value == 2) && this.premodules[i].questions[mi].copympi && this.premodules[i].questions[mi].dateAuthorised) {
            let q = JSON.parse(JSON.stringify(this.premodules[i].questions[mi]))
            q.dateAuthorised = undefined;
            q.answers = []
            collection.questions.push(q)
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((this.premodules[i].data.tyres[mi].value == 1 || this.premodules[i].data.tyres[mi].value == 2) && this.premodules[i].data.tyres[mi].copympi && this.premodules[i].data.tyres[mi].dateAuthorised) {
            let q: VHCQuestion = {
              name: 'Tyre, '+(m.name+' -> '+m.size+' '+m.loadindex),
              name2: 'Tyre, '+(m.name+' -> '+m.size+' '+m.loadindex),
              answers: [],
              value: m.value,
              recommendation: m.recommendation,
              recommendation2: m.recommendation2,
              dateAuthorised: undefined,
            }
            collection.questions.push(JSON.parse(JSON.stringify(q)))
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((this.premodules[i].data.brakes[mi].value == 1 || this.premodules[i].data.brakes[mi].value == 2) && this.premodules[i].data.brakes[mi].copympi && this.premodules[i].data.brakes[mi].dateAuthorised) {
            let q: VHCQuestion = {
              name: 'Brake, '+m.name,
              name2: 'Brake, '+m.name,
              answers: [],
              value: m.value,
              recommendation: m.recommendation,
              recommendation2: m.recommendation2,
              dateAuthorised: undefined,
            }
            collection.questions.push(JSON.parse(JSON.stringify(q)))
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (this.premodules[i].data[mi].copympi && this.premodules[i].data[mi].dateAuthorised) {
            collection.questions.push(JSON.parse(JSON.stringify(this.premodules[i].data[mi])))
            let q: VHCQuestion = {
              name: m.name,
              name2: m.name2,
              answers: [],
              value: 0,
              recommendation: "",
              recommendation2: "",
              dateAuthorised: undefined,
            }
            collection.questions.push(JSON.parse(JSON.stringify(q)))
          }
        });
      }
    })
    return collection;
  }

  collectJobsFromVhc(themodules:Vhcmodule[]) {
    let collection:DmsTask[] = []
    themodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((q, mi) => {
          if ((themodules[i].questions[mi].value == 1 || themodules[i].questions[mi].value == 2) && themodules[i].questions[mi].dateAuthorised) {
            if (Array.isArray(q.jobs)) {
              q.jobs.forEach(job => {
                collection.push({
                  ItemID: q.portalid,
                  ItemName: job.name,
                  Remarks1: q.recommendation?q.recommendation:"",
                  Remarks2: q.recommendation2?q.recommendation2:"",
                  Color: q.value == 1 ? 'red' : 'amber',
                  code: job.code,
                  duration: job.hour*60
                })
              });
            }
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((themodules[i].data.tyres[mi].value == 1 || themodules[i].data.tyres[mi].value == 2) && themodules[i].data.tyres[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  ItemID: m.portalid,
                  ItemName: job.name,
                  Remarks1: m.recommendation?m.recommendation:"",
                  Remarks2: m.recommendation2?m.recommendation2:"",
                  Color: m.value == 1 ? 'red' : 'amber',
                  code: job.code,
                  duration: job.hour*60
                })
              });
            }
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((themodules[i].data.brakes[mi].value == 1 || themodules[i].data.brakes[mi].value == 2) && themodules[i].data.brakes[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  ItemID: m.portalid,
                  ItemName: job.name,
                  Remarks1: m.recommendation?m.recommendation:"",
                  Remarks2: m.recommendation2?m.recommendation2:"",
                  Color: m.value == 1 ? 'red' : 'amber',
                  code: job.code,
                  duration: job.hour*60
                })
              });
            }
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (themodules[i].data[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  ItemID: m.portalid,
                  ItemName: job.name,
                  Remarks1: m.recommendation?m.recommendation:"",
                  Remarks2: m.recommendation2?m.recommendation2:"",
                  Color: m.value == 1 ? 'red' : 'amber',
                  code: job.code,
                  duration: job.hour*60
                })
              });
            }
          }
        });
      }
    })
    return collection;
  }

  collectPartsVHC(themodules: Vhcmodule[], showUnauthorised=true): Part[] {
    const parts: Part[] = [];
    themodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((q, mi) => {
          if ((themodules[i].questions[mi].value == 1 || themodules[i].questions[mi].value == 2) && (themodules[i].questions[mi].dateAuthorised || showUnauthorised)) {
            if (Array.isArray(q.parts)) {
              parts.push(...q.parts)
            }
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((themodules[i].data.tyres[mi].value == 1 || themodules[i].data.tyres[mi].value == 2) && (themodules[i].data.tyres[mi].dateAuthorised || showUnauthorised)) {
            if (Array.isArray(m.parts)) {
              parts.push(m.parts)
            }
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((themodules[i].data.brakes[mi].value == 1 || themodules[i].data.brakes[mi].value == 2) && (themodules[i].data.brakes[mi].dateAuthorised || showUnauthorised)) {
            if (Array.isArray(m.parts)) {
              parts.push(...m.parts)
            }
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (themodules[i].data[mi].dateAuthorised || showUnauthorised) {
            if (Array.isArray(m.parts)) {
              parts.push(...m.parts)
            }
          }
        });
      }
    })
    return parts;
  }

  collectJobsIQP(themodules:Vhcmodule[]) {
    let collection:IQTask[] = []
    themodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((q, mi) => {
          if ((themodules[i].questions[mi].value == 1 || themodules[i].questions[mi].value == 2) && themodules[i].questions[mi].dateAuthorised) {
            if (Array.isArray(q.jobs)) {
              q.jobs.forEach(job => {
                collection.push({
                  Duration: job.hour*60,
                  Order: job.code,
                  Name: job.name,
                  Type: "Task",
                  Employabilities: [],
                  BaseId: job.code,
                  Team: false
                })
              });
            }
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((themodules[i].data.tyres[mi].value == 1 || themodules[i].data.tyres[mi].value == 2) && themodules[i].data.tyres[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  Duration: job.hour*60,
                  Order: job.code,
                  Name: job.name,
                  Type: "Task",
                  Employabilities: [],
                  BaseId: job.code,
                  Team: false
                })
              });
            }
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((themodules[i].data.brakes[mi].value == 1 || themodules[i].data.brakes[mi].value == 2) && themodules[i].data.brakes[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  Duration: job.hour*60,
                  Order: job.code,
                  Name: job.name,
                  Type: "Task",
                  Employabilities: [],
                  BaseId: job.code,
                  Team: false
                })
              });
            }
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (themodules[i].data[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              m.jobs.forEach(job => {
                collection.push({
                  Duration: job.hour*60,
                  Order: job.code,
                  Name: job.name,
                  Type: "Task",
                  Employabilities: [],
                  BaseId: job.code,
                  Team: false
                })
              });
            }
          }
        });
      }
    })
    return collection;
  }

  generateEmptyJob(name: string) {
    return {
      name,
      name2: '',
      code: '',
      price: 0,
      hour: 0.2,
      vehiclevisuals: ''
    };
  }

  augmentWithNewJobs(themodules:Vhcmodule[]) {
    themodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((q, mi) => {
          if ((themodules[i].questions[mi].value == 1 || themodules[i].questions[mi].value == 2) && themodules[i].questions[mi].dateAuthorised) {
            if (Array.isArray(q.jobs)) {
              themodules[i].questions[mi].jobs.push(this.generateEmptyJob(themodules[i].questions[mi].name))
            } else {
              themodules[i].questions[mi].jobs = [this.generateEmptyJob(themodules[i].questions[mi].name)];
            }
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((themodules[i].data.tyres[mi].value == 1 || themodules[i].data.tyres[mi].value == 2) && themodules[i].data.tyres[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              themodules[i].data.tyres[mi].jobs.push(this.generateEmptyJob('tyre ' + themodules[i].data.tyres[mi].name))
            } else {
              themodules[i].data.tyres[mi].jobs = [this.generateEmptyJob('tyre ' + themodules[i].data.tyres[mi].name)];
            }
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((themodules[i].data.brakes[mi].value == 1 || themodules[i].data.brakes[mi].value == 2) && themodules[i].data.brakes[mi].dateAuthorised) {
            if (Array.isArray(m.jobs)) {
              themodules[i].data.brakes[mi].jobs.push(this.generateEmptyJob('brakes ' + themodules[i].data.brakes[mi].name))
            } else {
              themodules[i].data.brakes[mi].jobs = [this.generateEmptyJob('brakes ' + themodules[i].data.brakes[mi].name)];
            }
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (Array.isArray(m.jobs)) {
            themodules[i].data[mi].jobs.push(this.generateEmptyJob(m.name))
          } else {
            themodules[i].data[mi].jobs = [this.generateEmptyJob(m.name)];
          }
        });
      }
    })
  }

  async finalizeRecord() {
    const modal = await this.modalController.create({
      component: FollowupchooseDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'big-modal' : 'fullscreen-modal',
      componentProps: {
        vhcrecord: this.vhcrecord,
        premodules: this.premodules,
        mpimodules: this.mpimodules,
        qimodules: this.qimodules,
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
    }
  }

  async assignEmp(k) {
    const emp = await this.helper.employeePicker(this.vhcrecord.jlocation_code.toString())
    if (emp) {
      let keys = [k]
      if(k=="pre"){
        const alert = await this.alertController.create({
          header: 'Quick apply',
          subHeader: `Apply this assignee to entire ${this.vhcrecord.naming.Pre.name.toLowerCase()} and the ${this.vhcrecord.naming.MPI.name.toLowerCase()} steps?`,
          buttons: [{ text: 'Do nothing', role: 'no' }, { text: 'Do always', role: 'yes' }],
        });
        await alert.present();
        let { role } = await alert.onDidDismiss();
        if(role=='yes')
          keys = [k,"prejp","preauth","mpi","auth"]
      }
      keys.forEach(key=>{
        const id = emp ? emp.id : '';
        const name = emp ? emp.name : '';
        const idx = this.vhcrecord.assignees[key].findIndex(u => u.id == id+'');
        if (idx == -1) {
          this.vhcrecord.assignees[key].push({id, name});
        } else if (keys.length==1) {
          this.helper.notify('This employee is already assigned');
        }
      })
      await this.updateRecordWithObj({assignees: this.vhcrecord.assignees});
    }
  }

  @ConfirmDelete("Do you want to unassign this employee?")
  async removeEmp(key, index) {
    const eid = this.vhcrecord.assignees[key][index].id;
    this.vhcrecord.assignees[key].splice(index, 1)
    let updateresult = await this.updateRecordWithObj({assignees: this.vhcrecord.assignees})
    if (updateresult) {
      console.log('Upload')
      await this.helper.notifyUser(eid, `You were removed from '${key}' in vhc record ${this.vhcrecord.id}.`, `vhc-record;id=${this.vhcrecord.id}`)
    }
  }

  async openVehicle(vin: string) {
    this.router.navigate(['/vehicles/editor', { vin: vin }]);
  }

  async openPre() {
    const modal = await this.modalController.create({
      component: PrescreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        description: this.vhcrecord.description,
        premodules: this.premodules,
        tyrebrands: this.tyrebrands,
        brakediscpads: this.brakediscpads,
        completed: this.vhcrecord.completedpre
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep()
        this.vhcrecord.completedpre = this.helper.getDateTime();
        this.vhcrecord.status = VHCSTATUS.jp;
        if (!await this.updateRecordFromStep('pre')) {
          this.vhcrecord.completedpre = undefined;
          this.vhcrecord.status = VHCSTATUS.pre;
          this.openPre();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
        // pre step completed, now send to dms if needed
        const exconf = await this.supa.ExternalConfig();
        if (exconf.name === 'Keyloop') {
          this.augmentWithNewJobs(this.premodules);
          await this.sendJobsToKeyloop(this.vhcrecord.iqjc_id, 'pre-inspection items', this.collectJobsFromVhc(this.premodules))
        }
        this.autoNextStep();
        // else clause is for saving pre step without completing
      } else {
        if (!await this.updateRecordFromStep('pre')) {
          this.openPre();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  async assignCurrentUserToStep() {
    const userobj = this.supa.LoginObject.getValue();
    let assigneeindex = this.vhcrecord.status;
    if ((this.vhcrecord.status == 'jp' || this.vhcrecord.status == 'auth') && !this.vhcrecord.completedmpi) {
      assigneeindex = 'pre'+assigneeindex
    }
    const userindex = this.vhcrecord.assignees[assigneeindex].findIndex((u:any) => u.id == userobj.id)
    if (userindex < 0) {
      this.vhcrecord.assignees[assigneeindex].push({id: userobj.id, name: userobj.fullname});
      await this.updateRecordWithObj({assignees: this.vhcrecord.assignees});
    }
  }

  async sendJobsToKeyloop(jcid: string, description: string, jobs: DmsTask[]) {
    const load = await this.helper.getLoader();
    load.message = 'Adding to jobcard in Keyloop';
    await load.present()

    const jobsforkeyloop = {
        description,
        labor: jobs.map(j => {
          return {
            laborCode: 'SRVC010',
            brandCode: 'DEALER',
            description: j.ItemName,
            allowedTimeInMinutes: j.duration ? j.duration : 10,
            linePayer: 'CUSTOMER',
            linkReference: '1'
          }
        }
      )
    };

    // Post
    const result = (await this.supa.supabase.rpc('add_job_by_id', {jc_id: jcid, job: jobsforkeyloop}).single()).data;
    console.log(result);
    load.dismiss();
  }

  async openPreJP() {
    const modal = await this.modalController.create({
      component: JobPartscreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        modules: this.premodules,
        title: `${this.vhcrecord.naming.Pre.abbreviation} - Jobs & Parts`,
        completed: this.vhcrecord.completedprejp,
        ispre: true,
        vhcrecord: this.vhcrecord,
        vehicle: this.vehicle
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep()
        this.vhcrecord.completedprejp = this.helper.getDateTime();
        this.vhcrecord.status = VHCSTATUS.auth;
        if (!await this.updateRecordFromStep('prejp')) {
          this.vhcrecord.completedprejp = undefined;
          this.vhcrecord.status = VHCSTATUS.jp;
          this.openPreJP();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
        this.autoNextStep();
      } else {
        if (!await this.updateRecordFromStep('prejp')) {
          this.openPreJP();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  async openMpi() {

    const mindex = this.mpimodules.findIndex(m => m.type === 'Maintenance');
    if (mindex >= 0) {
      this.chooseMaintenanceTemplate(mindex);
      console.log('Opening mpi chooser');
      return;
    }

    const modal = await this.modalController.create({
      component: MpiscreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        name:this.vhcrecord.naming.MPI.name,
        description: this.vhcrecord.description,
        mpimodules: this.mpimodules,
        tyrebrands: this.tyrebrands,
        completed: this.vhcrecord.completedmpi,
        brakediscpads: this.brakediscpads
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep()
        this.vhcrecord.completedmpi = this.helper.getDateTime();
        this.vhcrecord.status = VHCSTATUS.jp;
        if (!await this.updateRecordFromStep('mpi')) {
          this.vhcrecord.completedmpi = undefined;
          this.vhcrecord.status = VHCSTATUS.mpi;
          this.openPre();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
        // mpi step completed, now send to dms if needed
        const exconf = await this.supa.ExternalConfig();
        if (exconf.name === 'Keyloop') {
          this.augmentWithNewJobs(this.mpimodules);
          await this.sendJobsToKeyloop(this.vhcrecord.iqjc_id, 'Multi-point-inspection items', this.collectJobsFromVhc(this.mpimodules))
        }
        this.autoNextStep();
      } else if (data.resetmt) {
        const mindex = this.mpimodules.findIndex(m => m.name.startsWith("Maintenance - "));
        console.log(mindex);
        if (mindex >= 0) {
          console.log("deleting qblock");
          this.mpimodules[mindex] = undefined
          console.log("adding mblock");
          this.mpimodules[mindex] = new VhcMaintenancedModule(this.helper, this.supa);
          this.helper.notify('Please choose a new maintenance table, note that the step is unsaved', 'primary', 5000);
          this.openMpi();
          return;
        }
      } else {
        if (!await this.updateRecordFromStep('mpi')) {
          this.openMpi();
          this.helper.notify('Could not be saved!', 'danger', 5000);
          return
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  // async toggleDms() {
  //   this.vhcrecord.organisationdata.connectedToDms = !this.vhcrecord.organisationdata.connectedToDms;
  //   let r = await this.updateRecordWithObj({organisationdata: this.vhcrecord.organisationdata})
  //   if(!r) {
  //     this.vhcrecord.organisationdata.connectedToDms = !this.vhcrecord.organisationdata.connectedToDms;
  //   }
  // }

  async openAuth(isPre=false) {
    const modal = await this.modalController.create({
      component: AuthscreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        vhcrecord: this.vhcrecord,
        vehicle: this.vehicle,
        premodules: this.premodules,
        mpimodules: this.mpimodules,
        qimodules: this.qimodules,
        completed: isPre ? this.vhcrecord.completedpreauth : this.vhcrecord.completedauth,
        title: isPre ? `${this.vhcrecord.naming.Pre.name} - Authorisation` : `${this.vhcrecord.naming.MPI.name} - Authorisation`,
        brakediscpads: this.brakediscpads
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep();
        if (isPre) {

          const exconf = await this.supa.ExternalConfig();

          if (exconf.name === 'Keyloop') {
            let jobsforkeyloop = [{
                "description": "pre-inspection items",
                "labor": this.collectJobsFromVhc(this.premodules).map(j => {
                  return {
                    "laborCode": "ACC",
                    "brandCode": "DEALER",
                    "description": j.ItemName,
                    "allowedTimeInMinutes": j.duration ? j.duration : 10,
                    "linePayer": "CUSTOMER",
                    "linkReference": "1"
                  }
                }
              )
            }];
            let payloadkeyloop = {
              "vehicleId": "16dc2af2-d993-46c7-9f6e-5c5feda3fa5a",
              "customerId": "00-473046",
              "appointment": {
                "dueInDateTime": "2023-12-11T08:37:13Z",
                "dueOutDateTime": "2023-12-12T08:37:13Z"
              },
              "jobs": [...jobsforkeyloop]
            }
            const vehicleidkeyloop = (await this.supa.supabase.rpc('get_vehicle_id', {vin: this.vhcrecord.vin}).single()).data;
            const customeridkeyloop = (await this.supa.supabase.rpc('get_customer_id', {vid: vehicleidkeyloop}).single()).data;
            if (customeridkeyloop.relations?.length) {
              payloadkeyloop.customerId = customeridkeyloop.relations[0].customer?.customerId || '01-0000008765';
            }
            payloadkeyloop.vehicleId = vehicleidkeyloop;

            // Post
            let result = (await this.supa.supabase.rpc('create_job_card', {jobcard: payloadkeyloop}).single()).data;
            this.vhcrecord.iqjc_id = result ? result : null;

            await this.updateRecordWithObj({iqjc_id: this.vhcrecord.iqjc_id});
          }

          // if (this.vhcrecord.organisationdata.connectedToDms) {
          //   if(!(await this.getVehicle())) {
          //     await this.helper.promptYesNo('Vehicle not in the DMS', 'Please create the vehicle in the DMS before you can continue', ()=>{}, 'Ok')
          //     return;
          //   }
          //   console.log(this.collectJobsFromVhc(this.premodules));
          //   this.vhcrecord.organisationdata.prepostresult = await this.dms.sendDMS_PRE_ITEMS(this.collectJobsFromVhc(this.premodules), this.vhcrecord, this.vehicle);
          //   await this.updateRecordWithObj({organisationdata: this.vhcrecord.organisationdata, iqjc_id: this.vhcrecord.iqjc_id});
          //   if ((this.vhcrecord.organisationdata.prepostresult as DmsCreateJobcardResult).response.length == 0) {
          //     await this.helper.promptYesNo('Jobcard(s) not created in the dms', 'Please check if the vehicle is in the DMS or if there is a jobcard already', ()=>{}, 'Ok')
          //     return;
          //   }
          // }
          // Completed the pre auth
          this.vhcrecord.completedpreauth = this.helper.getDateTime();
          this.vhcrecord.status = 'mpi'
          let copyOverTheseItems = this.collectCopyToMPI();
          if (copyOverTheseItems.questions.length > 0) {
            this.mpimodules.push(copyOverTheseItems);
          }
        } else {
          // Completed the mpi auth
          this.vhcrecord.completedauth = this.helper.getDateTime();
          this.vhcrecord.status = 'qi'

          if (this.vhcrecord.qimodules.length === 0) {
            this.vhcrecord.completedqi = this.helper.getDateTime();
            this.vhcrecord.status = VHCSTATUS.fin;
          }
        }
        if (!await this.updateRecordFromStep(isPre ? 'preauth' : 'auth')) {
          if (isPre) {
            // Completed the pre auth
            this.vhcrecord.completedpreauth = undefined
            this.vhcrecord.status = 'jp'
            let copiedOverIdx = this.mpimodules.findIndex(m => m.name == this.COPYOVERNAME);
            if (copiedOverIdx >= 0) {
              this.mpimodules.splice(copiedOverIdx, 1)
            }
          } else {
            // Completed the mpi auth
            this.vhcrecord.completedqi = undefined
            this.vhcrecord.completedauth = undefined
            this.vhcrecord.status = 'jp'
          }
          this.openAuth(isPre);
          this.helper.notify('Could not be saved!', 'danger', 5000);
        } else {
          let iqjobs = this.collectJobsIQP(isPre ? this.premodules : this.mpimodules)
          const locidx = this.jlocations.findIndex(l => l.code == this.vhcrecord.jlocation_code)
          if (locidx >= 0) {
            let iqplanningurl = this.jlocations[locidx].iqplanning;
            if ((""+iqplanningurl).length > 8 && !isPre) {
              await this.supa.IQPAddTasksAndPlan(iqplanningurl, this.vhcrecord.iqjc_id, iqjobs);
            }
          }
        }
        this.autoNextStep();
      } else {
        if (!await this.updateRecordFromStep(isPre ? 'preauth' : 'auth')) {
          this.openAuth(isPre);
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  async openJP() {
    const modal = await this.modalController.create({
      component: JobPartscreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        modules: this.mpimodules,
        ignorePartsDMSImport: this.collectPartsVHC(this.premodules, true).map(p => p.name),
        completed: this.vhcrecord.completedjp,
        title: `${this.vhcrecord.naming.MPI.abbreviation} - Jobs & Parts`,
        vhcrecord: this.vhcrecord,
        vehicle: this.vehicle
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep()
        this.vhcrecord.completedjp = this.helper.getDateTime();
        this.vhcrecord.status = VHCSTATUS.auth;
        if (!await this.updateRecordFromStep('jp')) {
          this.vhcrecord.completedjp = undefined;
          this.vhcrecord.status = VHCSTATUS.jp;
          this.openJP();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
        this.autoNextStep();
      } else {
        if (!await this.updateRecordFromStep('jp')) {
          this.openJP();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  async openQi() {
    const modal = await this.modalController.create({
      component: QiscreenDialog,
      cssClass: (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'fullscreen-modal' : 'fullscreen-modal',
      componentProps: {
        name: this.vhcrecord.naming.QI.name,
        description: this.vhcrecord.description,
        qimodules: this.qimodules,
        tyrebrands: this.tyrebrands,
        brakediscpads: this.brakediscpads,
        completed: this.vhcrecord.completedqi
      }
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();
    if (data) {
      if (data.completestep) {
        await this.assignCurrentUserToStep()
        this.vhcrecord.completedqi = this.helper.getDateTime();
        this.vhcrecord.status = VHCSTATUS.fin;
        if (!await this.updateRecordFromStep('qi')) {
          this.vhcrecord.completedqi = undefined;
          this.vhcrecord.status = VHCSTATUS.qi;
          this.openQi();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      } else {
        if (!await this.updateRecordFromStep('qi')) {
          this.openQi();
          this.helper.notify('Could not be saved!', 'danger', 5000);
        }
      }
    } else {
      this.helper.notify('Closed without saving!', 'primary', 5000);
    }
  }

  getModulesForJobsPartsAutoNextStep(modules:Vhcmodule[]) {
    return modules.filter((module:Vhcmodule) => {
      if (module.type == 'Questionblock') {
        return (module as VhcQuestionblockModule).questions.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Tyres') {
        return (module as VhcTyreModule).data.tyres.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Brakes') {
        return (module as VhcBrakeModule).data.brakes.findIndex(v => v.value == 2 || v.value == 1 || v.dateAuthorised) >= 0
      } else if (module.type == 'Cross_sell') {
        return true
      } else {
        return false
      }
    });
  }

  async autoNextStep(needSave:boolean = false, skipSteps=0) {
    console.log('Attempting auto next step');
    switch (this.vhcrecord.status) {
      case "new":
        this.vhcrecord.status = 'pre';
        return this.autoNextStep(true, ++skipSteps);
      case "pre":
        if (this.vhcrecord.premodules.length === 0) {
          // skip to mpi, no need for the step, no need for jobs & parts thus no need for auth
          this.vhcrecord.completedpre = this.helper.getDateTime();
          this.vhcrecord.completedprejp = this.helper.getDateTime();
          this.vhcrecord.completedpreauth = this.helper.getDateTime();
          this.vhcrecord.status = VHCSTATUS.mpi;
          return this.autoNextStep(true, skipSteps+3);
        }
        break;
      case "mpi":
        if (this.vhcrecord.mpimodules.length === 0) {
          // skip to qi, no need for the step, no need for jobs & parts thus no need for auth
          this.vhcrecord.completedmpi = this.helper.getDateTime();
          this.vhcrecord.completedjp = this.helper.getDateTime();
          this.vhcrecord.completedauth = this.helper.getDateTime();
          this.vhcrecord.status = VHCSTATUS.qi;
          return this.autoNextStep(true, skipSteps+3);
        }
        break;
      case "jp":
        if (this.vhcrecord.completedmpi) {
          // mpi jp
          if (this.getModulesForJobsPartsAutoNextStep(this.mpimodules).length === 0) {
            // skip to qi, no need for the step, no need for auth
            this.vhcrecord.completedjp = this.helper.getDateTime();
            this.vhcrecord.completedauth = this.helper.getDateTime();
            this.vhcrecord.status = VHCSTATUS.qi;
            return this.autoNextStep(true, skipSteps+2);
          }
        } else {
          // pre jp
          if (this.getModulesForJobsPartsAutoNextStep(this.premodules).length === 0) {
            // skip to mpi, no need for the step, no need for auth
            this.vhcrecord.completedprejp = this.helper.getDateTime();
            this.vhcrecord.completedpreauth = this.helper.getDateTime();
            this.vhcrecord.status = VHCSTATUS.mpi;
            return this.autoNextStep(true, skipSteps+2);
          }
        }
        break;
      case "auth":
        // this.openAuth(!this.vhcrecord.completedpreauth);
        console.log('Should be impossible to skip this case.. since it would be skipped if either jp step is empty. If either JP is not empty then the step needs to take place');
        break;
      case "qi":
        if (this.vhcrecord.qimodules.length === 0) {
          // skip to qi, no need for the step, no need for jobs & parts thus no need for auth
          this.vhcrecord.completedqi = this.helper.getDateTime();
          this.vhcrecord.status = VHCSTATUS.fin;
          return this.autoNextStep(true, skipSteps+1);
        }
        break;
      case "fin":
        console.log('User needs to perform finalize step')
        break;
      default:
        break;
    }
    if (needSave) {
      console.log('Apparrently through recursive calls the record was altered and needs saving.');
      await this.saveIQTools()
      this.helper.notify('Automatically completed ' + skipSteps + ' step' + (skipSteps === 1 ? '' : 's'))
    } else {
      console.log('No next step indicated.');
    }
  }

  async chooseMaintenanceTemplate(mindex) {

    const folder = await this.helper.vinToFolder(this.vhcrecord?.vin);
    if (folder !== '') {
      // SPECIAL ROUTINE FOR VIN COUPLER
      // do we know mileage from osb????
      const osbitemsidx = this.vhcrecord.premodules.findIndex((m: any) => m.name === 'OSB Booked items');
      if (osbitemsidx >= 0) {
        const kmreg = new RegExp('^[0-9]+ KM', 'g')
        const selectedmileageidx = (this.vhcrecord.premodules[osbitemsidx].data as any[]).findIndex(d => kmreg.test((d.name as string)));
        if (selectedmileageidx >= 0) {
          const mileagestring = (this.vhcrecord.premodules[osbitemsidx].data[selectedmileageidx].name).split(' KM')[0];
          const mileagenum = Math.floor(Number.parseInt(mileagestring, 10) / 1000);
          console.log('Selected mileage should be ' + mileagestring + ' with slotvalue ' + mileagenum);

          const folderid = await this.supa.supabase.from('folderstructure').select('id').eq('category', 'maintenancetable').eq('name', folder).single();
          if (folderid.data?.id) {
            const tables = await this.supa.supabase.from('maintenancetable').select('*').eq('parent', folderid.data.id);
            console.log(tables);
            if (tables.data.length === 1) {
              console.log('Only one, select it and the mileage..');

              const slotindexontable = tables.data[0].itemdata.slotvalues.findIndex(s => s === mileagenum);
              if (slotindexontable >= 0) {
                this.makeQbFromMaintenance(tables.data[0], slotindexontable, mindex)
                return
              }
            }
          }
        }
      }
      // SPECIAL ROUTINE FOR VIN COUPLER ENDS
    }

    // Choose the template
    const maintenancecounter = await this.supa.supabase.from('maintenancetable').select('id', {count: 'exact'});
    let preselectedId = -1;
    if (maintenancecounter.count === 1) {
      console.log('If exactly one table present, just choose it. If none exist, show the empty folder, if more exist, a choice needs to take place by the user');
      preselectedId = maintenancecounter.data[0].id;
    }

    if (preselectedId === -1) {
      const result = await this.helper.createAndOpenModal(MaintenancechooseDialog, {folder}, 'fullscreen-modal');

      if (!result.data) {
        return false;
      }

      preselectedId = result.data.id;
    }


    // Retrieve blueprint
    let maintenancedata = await this.supa.supabase.from('maintenancetable').select("*").eq('id', preselectedId).single();
    if (maintenancedata.error) {
      this.helper.notify('There was an error while getting the template', 'danger')
      return false;
    }

    // Choose the mileage category
    let slotvalues = maintenancedata.data.itemdata.slotvalues.map((v, i) => { return { name: v + "K", label: v + "K", type: 'radio', value: i } });
    if (slotvalues.length) {
      console.log('If there is only one mileage, choose it instead of presenting the prompt.');
      this.makeQbFromMaintenance(maintenancedata.data, 0, mindex)
    } else {
      await this.helper.createAndOpenAlert('Select the mileage', 'Please tick the correct mileage from the list', async (v) => {
        if (v != undefined) {
          // confirm the last time before conversion
          await this.helper.promptYesNo(`Do you want to use "${maintenancedata.data.name}", with ${maintenancedata.data.itemdata.slotvalues[v]}K?`, 'Press OK to continue', () => {
            this.makeQbFromMaintenance(maintenancedata.data, v, mindex)
          }
          )
        }
      }, slotvalues)
    }

  }

  makeQbFromMaintenance(maintenancedata, kmidx, mindex) {
    let qb = new VhcQuestionblockModule(this.helper, this.supa)
          qb.name = 'Maintenance - ' + maintenancedata.name + ('-' + maintenancedata.itemdata.slotvalues[kmidx] + 'K')
          qb.name2 = 'Maintenance - ' + maintenancedata.name + ('-' + maintenancedata.itemdata.slotvalues[kmidx] + 'K')
          qb.questions = maintenancedata.itemdata.items
            .filter(qitem => qitem.slotvalues[kmidx] != "")
            .map(qitem => {
              return {
                name: qitem.text,
                name2: qitem.text2,
                answers: qitem.recommendations,
                value: 0,
                images: [],
                recommendation: '',
                recommendation2: '',
                maintenancenote: qitem.slotvalues[kmidx]
              }
            }
            );
          console.log(maintenancedata.itemdata);
          console.log(qb);
          this.mpimodules[mindex] = qb
          this.helper.notify('The maintenance table was loaded successfully. You can save the record; or edit the mpi now', 'success', 5000)
          this.openMpi();
          return true
  }

  async deactivate() {
    if (this.vhcrecord.isdone) {
      this.helper.notify("The record is already finalized!")
      return;
    }
    let code = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
    this.helper.promptInput('Do you want to continue?', 'Please enter `' + code + '` and press deactivate', '', (v) => {
      if (code != v) {
        this.helper.notify('The wrong code was entered', 'warning')
      } else {
        this.supa.supabase.from('vhcrecord').update({ deactivated: true, isdone: true }, { returning: 'minimal' }).eq('id', this.vhcrecord.id).then(r => {
          if (r.error) {
            this.helper.notify('The record could not be deactivated', 'danger')
          } else {
            this.helper.notify('The record was deactivated', 'success')
            this.router.navigate(['/vehicles/editor', { vin: this.vehicle.vin }]);
          }
        })
      }
    }, code, 'text', 'deactivate')
  }

  async uploadImgAndReplaceUrl(img: string, loadText = 'Uploading image') {
    const loading = await this.loadingController.create({
      message: loadText,
      spinner: 'bubbles'
    });
    await loading.present();
    const path = ['userpics/pic_', this.helper.getUUID(), '.jpg'].join('');
    const base64 = img.split('base64,')[1]
    const data = await this.supa.supabase.storage.from('internaldesign').upload(path, decode(base64), {
      contentType: 'image/jpeg'
    });
    loading.dismiss();
    console.log('Pic is done uploading');
    if (!data.error) {
      return (await this.supa.supabase.storage.from('internaldesign').getPublicUrl(path)).publicURL;
    }
    console.log(data);
    return img;
  }
  async findImgForStorageAndUpload(modules: Vhcmodule[]) {
    console.log('Finding and uploading images');
    let picCounter = 0;
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].getType() === 'Questionblock') {
        for (let qidx = 0; qidx < modules[i].questions.length; qidx++) {
          for (let imidx = 0; imidx < modules[i].questions[qidx].images.length; imidx++) {
            if (modules[i].questions[qidx].images[imidx].startsWith('data:image')) {
              modules[i].questions[qidx].images[imidx] = await this.uploadImgAndReplaceUrl(modules[i].questions[qidx].images[imidx], 'Uploading picture ' + (++picCounter));
            }
          }
        }
      } else if (modules[i].getType() === 'Brakes') {
        for (let qidx = 0; qidx < modules[i].data.brakes.length; qidx++) {
          for (let imidx = 0; imidx < modules[i].data.brakes[qidx].images.length; imidx++) {
            if (modules[i].data.brakes[qidx].images[imidx].startsWith('data:image')) {
              modules[i].data.brakes[qidx].images[imidx] = await this.uploadImgAndReplaceUrl(modules[i].data.brakes[qidx].images[imidx], 'Uploading picture ' + (++picCounter));
            }
          }
        }
      } else if (modules[i].getType() === 'Tyres') {
        for (let qidx = 0; qidx < modules[i].data.tyres.length; qidx++) {
          for (let imidx = 0; imidx < modules[i].data.tyres[qidx].images.length; imidx++) {
            if (modules[i].data.tyres[qidx].images[imidx].startsWith('data:image')) {
              modules[i].data.tyres[qidx].images[imidx] = await this.uploadImgAndReplaceUrl(modules[i].data.tyres[qidx].images[imidx], 'Uploading picture ' + (++picCounter));
            }
          }
        }
      } else if (modules[i].getType() === 'Media') {
        for (let imidx = 0; imidx < modules[i].data.images.length; imidx++) {
          if (modules[i].data.images[imidx].startsWith('data:image')) {
            modules[i].data.images[imidx] = await this.uploadImgAndReplaceUrl(modules[i].data.images[imidx], 'Uploading picture ' + (++picCounter));
          }
        }
      }
    }
  }

  updateRecordFromStep(prevStatus: string): Promise<boolean> {
    if (this.vhcrecord.isdone) {
      this.helper.notify("The record is already finalized!")
      return;
    }
    return new Promise(async (resolve, reject) => {
      let d = /*await this.helper.promptYesNo('Internet connection', 'Do you have a strong internet connection at this location?', */ async () => {

        // Loading modal
        const loading = await this.loadingController.create({
          message: 'Uploading the vhc record to the database, one moment please',
          spinner: 'bubbles'
        });
        await loading.present();

        let setPortalPass = false;

        // setup the new data
        let newdataObj: any = {
        };
        switch (prevStatus) {
          case "pre":
            newdataObj.completedpre = this.vhcrecord.completedpre;
            console.log(this.premodules);
            await this.findImgForStorageAndUpload(this.premodules);
            console.log(this.premodules);
            newdataObj.premodules = this.premodules.map(vhcmod => vhcmod.toDatabase(true));
            setPortalPass = true;
            break;
          case "mpi":
            newdataObj.completedmpi = this.vhcrecord.completedmpi;
            await this.findImgForStorageAndUpload(this.mpimodules);
            newdataObj.mpimodules = this.mpimodules.map(vhcmod => vhcmod.toDatabase(true));
            setPortalPass = true;
            break;
          case "jp":
            await this.findImgForStorageAndUpload(this.mpimodules);
            newdataObj.mpimodules = this.mpimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.completedjp = this.vhcrecord.completedjp;
            break;
          case "prejp":
            await this.findImgForStorageAndUpload(this.premodules);
            newdataObj.premodules = this.premodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.completedprejp = this.vhcrecord.completedprejp;
            break;
          case "auth":
            await this.findImgForStorageAndUpload(this.mpimodules);
            await this.findImgForStorageAndUpload(this.qimodules);
            await this.findImgForStorageAndUpload(this.premodules);
            newdataObj.mpimodules = this.mpimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.premodules = this.premodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.qimodules = this.qimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.completedauth = this.vhcrecord.completedauth;
            newdataObj.authobjects = this.vhcrecord.authobjects;
            newdataObj.portalstatus = this.vhcrecord.portalstatus;
            newdataObj.portalsettings = this.vhcrecord.portalsettings;
            break;
          case "preauth":
            await this.findImgForStorageAndUpload(this.mpimodules);
            await this.findImgForStorageAndUpload(this.qimodules);
            await this.findImgForStorageAndUpload(this.premodules);
            newdataObj.mpimodules = this.mpimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.premodules = this.premodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.qimodules = this.qimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.completedpreauth = this.vhcrecord.completedpreauth;
            newdataObj.authobjects = this.vhcrecord.authobjects;
            newdataObj.portalstatus = this.vhcrecord.portalstatus;
            newdataObj.portalsettings = this.vhcrecord.portalsettings;
            break;
          case "qi":
            await this.findImgForStorageAndUpload(this.qimodules);
            newdataObj.qimodules = this.qimodules.map(vhcmod => vhcmod.toDatabase(true));
            newdataObj.completedqi = this.vhcrecord.completedqi;
            break;
          default:
            break;
        }
        newdataObj.status = this.vhcrecord.status;

        if (setPortalPass) {
          // Make sure the data has UID's
          this.setPortalPass();
        }

        // Upload the data and return the result.
        let { data, error } = await this.supa.supabase.from('vhcrecord').update(newdataObj, { returning: 'minimal' }).eq('id', this.vhcrecord.id)
        if (error) {
          this.helper.notify('Could not upload data', 'danger')
          loading.dismiss()
          resolve(false);
        } else {
          this.helper.notify('Data was uploaded', 'success')
          loading.dismiss()
          resolve(true);
        }
      };
      d();
      if (!d) {
        resolve(false);
      }
    });
  }

  async updateRecordWithObj(newdataObj) {
    if (this.vhcrecord.isdone) {
      this.helper.notify("The record is already finalized!")
      return;
    }
    // Loading modal
    const loading = await this.loadingController.create({
      message: 'Uploading the vhc record to the database, one moment please',
      spinner: 'bubbles'
    });
    await loading.present();
    // Upload the data and return the result.
    let { data, error } = await this.supa.supabase.from('vhcrecord').update(newdataObj, { returning: 'minimal' }).eq('id', this.vhcrecord.id)
    if (error) {
      this.helper.notify('Could not upload data', 'danger');
      loading.dismiss();
      return false;
    } else {
      this.helper.notify('Data was uploaded', 'success');
      loading.dismiss();
      return true;
    }
  }

  getVideoCallLink(ismeet:boolean) {
    return ismeet?'https://meet.iq-motive.com/vhcmeeting-'+this.vhcrecord.id+'-'+this.vhcrecord.vin : 'https://fuse.release.iq-motive.com/';
  }

  openVideoCall(ismeet:boolean) {
    this.helper.createAndOpenModal(
      IframeWrapperComponent, 
      {url: this.getVideoCallLink(ismeet)},
      'doubled-modal fullscreen-modal'  
    )
  }

  async sendVideoCallLink(ismeet:boolean) {
    if (!(this.vehicle.communicatewithowner ? this.vehicle.ownerphone : this.vehicle.driverphone).startsWith('+'))
    {
      this.helper.notify('The phonenumber should start with a country code like +31')
      return;
    }
    this.helper.promptYesNo(
      'Send video call URL', 
      'Do you want to send an sms with the video url to the vehicle ' + (this.vehicle.communicatewithowner ? 'owner' : 'driver') + '?', async ()=>{
        let load = await this.helper.getLoader();
        load.present();
        let res = await this.supa.supabase.rpc('send_sms', {
          recipient: this.vehicle.communicatewithowner ? this.vehicle.ownerphone : this.vehicle.driverphone,
          body: 'Click on the link to join the video call: ' + this.getVideoCallLink(ismeet)
        })
        console.log(res);
        if(res.error) {
          this.helper.notify('Could not send message, ' + res.error.message, 'danger')
        }
        if(res.data) {
          if ((res.data as any).errors) {
            try {
              
              this.helper.notify('Could not send message, ' + (res.data as any).errors[0].description, 'danger')
            } catch (error) {
              console.log('Strange error format detected.');
            }
          }
        }
        load.dismiss();
      });
  }

  subscriptions: Subscription[]=[]

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {s.unsubscribe()})
  }

  ngOnInit() {
    this.subscriptions.push(this.speech.CommandObservable.subscribe(async v => {
      const topModal = await this.modalController.getTop();
      if (topModal !== undefined) {
        console.log('In a modal right now, voice command ignored');
        return
      }
      const command = v.substring(13);
      console.log(command, topModal);
      switch (command) {
        case 'Open MPI':
          if (this.vhcrecord.completedpreauth) {this.openMpi()};
          break;
        case `Open ${this.vhcrecord.naming.Pre.name}`:
          this.openPre();
          break;
        case 'Open Current Step':
          this.openCurrent();
          break;
        case 'Open Vehicle':
          this.openVehicle(this.vhcrecord.vin);
          break;
      }
    }))
    this.canmanagelocation = this.supa.LoginObject.value?.permissions.includes("VhcRecordsManageLocation") == true
    this.route.params.subscribe(params => {
      if (Object.keys(params).includes('id')) {
        this.supa.supabase.from('vhcrecord').select('*').eq('id', params.id).single().then(v => {
          if (v.error) {
            console.log(v.error);
            this.helper.notify('Could not load this record', 'danger')
            this.router.navigate(['home'])
          } else {
            console.log(v.data);
            this.vhcrecord = v.data
            this.COPYOVERNAME = `Items copied from the ${this.vhcrecord.naming.Pre.name.toLowerCase()} (diagnose)`

            this.supa.supabase.from('vehicle').select().eq('vin', this.vhcrecord.vin).single().then(ve => {
              if (ve.error) {
                this.helper.notify('Could not load the associated vehicle', 'danger')
                this.router.navigate(['home'])
              } else {

                this.vehicle = ve.data

                this.supa.supabase.from('tyrebrand').select('id,name,expiredays,amberdepthmm,reddepthmm').then(tb => {
                  if (tb.error) {
                    this.helper.notify('Could not load tyre brands', 'danger')
                  } else {

                    this.supa.supabase.from('brakediscpad').select('*').then(bdp => {
                      if (bdp.error) {
                        this.helper.notify('Could not load brake disc & pads', 'danger')
                      } else {

                        this.supa.supabase.from<jlocation_name_code>('jlocation').select('code,name,iqplanning').then(async loc => {
                          if (loc.error) {
                            this.helper.notify('Could not load locations', 'danger')
                          } else {

                            if (this.supa.LoginObject.value?.permissions.includes('VhcAllLocations')) {
                              this.jlocations = loc.data;
                            } else {
                              this.jlocations = loc.data.filter(d => this.supa.LoginObject.value?.permissions.includes('Location'+d.code));
                            }

                            this.setLocationName();

                            // Load modules; now as setting tyrebrands, will state loading done. In the else we know data is there so its safe.
                            this.premodules = v.data.premodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true, true)),
                            this.mpimodules = v.data.mpimodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true, true)),
                            this.qimodules = v.data.qimodules.map(vhcmod => VhcModuleFactory(this.helper, this.supa, vhcmod, true, true)),

                            // Loading done
                            this.tyrebrands = tb.data;
                            this.brakediscpads = bdp.data;

                            this.autoNextStep();
                          }
                        })

                      }
                    })
                  }
                })
              }
            })
          }
        }, e => {
          console.log(e);
          this.helper.notify('Could not load this record', 'danger')
          this.router.navigate(['home'])
        })
      } else {
        this.router.navigate(['home'])
      }
    });
  }

  async setPortal() {
    let items: any = [
      {name: '0', label: 'Closed', value: 0, checked: 0 == this.vhcrecord.portalstatus, type: 'radio'},
      {name: '1', label: 'Waiting for approval', value: 1, checked: 1 == this.vhcrecord.portalstatus, type: 'radio'},
      {name: '2', label: 'Customer has submitted', value: 2, checked: 2 == this.vhcrecord.portalstatus, type: 'radio'},
    ];

    this.helper.createAndOpenAlert('Portal status', 'Please choose a status', async (v)=>{
      let d = await this.updateRecordWithObj({portalstatus: v});
      console.log(d);
      if (d) {
        this.helper.notify('The portal was updated', 'success')
        this.vhcrecord.portalstatus = v;
      } else {
        this.helper.notify('Could not update the portal', 'danger')
      }
      this.setLocationName();
    }, items, 'Ok', 'Cancel', "wider-alert");
  }

  async setLocation() {
    let items: any = this.jlocations.map((v, i) => { return { name: v.code, label: v.code + " - " + v.name, type: 'radio', value: v.code, checked: v.code == this.vhcrecord.jlocation_code } });
    items.unshift({name: 'all', label: 'All locations', value: '', type: 'radio', checked: this.vhcrecord.jlocation_code == ''})

    this.helper.createAndOpenAlert('Location', 'Please choose a location', async (v)=>{
      let d = await this.updateRecordWithObj({jlocation_code: v});
      console.log(d);
      if (d) {
        this.helper.notify('The location was updated', 'success')
        this.vhcrecord.jlocation_code = v;
      } else {
        this.helper.notify('Could not update the location', 'danger')
      }
      this.setLocationName();
    }, items, 'Ok', 'Cancel', "wider-alert");
  }

  setLocationName() {
    if (this.vhcrecord.jlocation_code == "") {
      this.currentLocation = "All locations";
      return
    }
    let lname = "";
    for(let i = 0; i < this.jlocations.length; i++) {
      if (this.jlocations[i].code == this.vhcrecord.jlocation_code) {
        lname = this.jlocations[i].code + " - " + this.jlocations[i].name
        break;
      }
    }
    this.currentLocation = lname == "" ? "Unknown location" : lname;
  }

  setPortalPass() {
    console.log('setting portal passes');
    this.premodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((m, mi) => {
          if ((this.premodules[i].questions[mi].value == 1 || this.premodules[i].questions[mi].value == 2) && !this.premodules[i].questions[mi].portalid) {
            this.premodules[i].questions[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((this.premodules[i].data.tyres[mi].value == 1 || this.premodules[i].data.tyres[mi].value == 2) && !this.premodules[i].data.tyres[mi].portalid) {
            this.premodules[i].data.tyres[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((this.premodules[i].data.brakes[mi].value == 1 || this.premodules[i].data.brakes[mi].value == 2) && !this.premodules[i].data.brakes[mi].portalid) {
            this.premodules[i].data.brakes[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (!this.premodules[i].data[mi].portalid) {
            this.premodules[i].data[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Customer_Satisfaction" && !v.data.portalid) {
        v.data.portalid = this.helper.getUUID()
      }
    })
    this.mpimodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((m, mi) => {
          if ((this.mpimodules[i].questions[mi].value == 1 || this.mpimodules[i].questions[mi].value == 2) && !this.mpimodules[i].questions[mi].portalid) {
            this.mpimodules[i].questions[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((this.mpimodules[i].data.tyres[mi].value == 1 || this.mpimodules[i].data.tyres[mi].value == 2) && !this.mpimodules[i].data.tyres[mi].portalid) {
            this.mpimodules[i].data.tyres[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((this.mpimodules[i].data.brakes[mi].value == 1 || this.mpimodules[i].data.brakes[mi].value == 2) && !this.mpimodules[i].data.brakes[mi].portalid) {
            this.mpimodules[i].data.brakes[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (!this.mpimodules[i].data[mi].portalid) {
            this.mpimodules[i].data[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Customer_Satisfaction" && !v.data.portalid) {
        v.data.portalid = this.helper.getUUID()
      }
    })
    this.qimodules.forEach((v, i) => {
      if (v.type == "Questionblock") {
        v.questions.forEach((m, mi) => {
          if ((this.qimodules[i].questions[mi].value == 1 || this.qimodules[i].questions[mi].value == 2) && !this.qimodules[i].questions[mi].portalid) {
            this.qimodules[i].questions[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Tyres") {
        v.data.tyres.forEach((m, mi) => {
          if ((this.qimodules[i].data.tyres[mi].value == 1 || this.qimodules[i].data.tyres[mi].value == 2) && !this.qimodules[i].data.tyres[mi].portalid) {
            this.qimodules[i].data.tyres[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Brakes") {
        v.data.brakes.forEach((m, mi) => {
          if ((this.qimodules[i].data.brakes[mi].value == 1 || this.qimodules[i].data.brakes[mi].value == 2) && !this.qimodules[i].data.brakes[mi].portalid) {
            this.qimodules[i].data.brakes[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Cross_sell") {
        v.data.forEach((m, mi) => {
          if (!this.qimodules[i].data[mi].portalid) {
            this.qimodules[i].data[mi].portalid = this.helper.getUUID();
          }
        });
      } else if (v.type == "Customer_Satisfaction" && !v.data.portalid) {
        v.data.portalid = this.helper.getUUID()
      }
    })
  }

  //https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  windowObjectReference: Window = null

  async print() {
    let settings = await this.supa.Settings();
    let inputs: AlertInput[] = [{
      label: 'Authorized items only',
      value: 'authonly',
      type: 'checkbox',
      checked: true
    }, {
      label: 'Include pre inspection',
      value: 'includepre',
      type: 'checkbox',
      checked: true
    }, {
      label: `Include ${this.vhcrecord.naming.MPI.name.toLowerCase()}`,
      value: 'includempi',
      type: 'checkbox',
      checked: true
    }, {
      label: `Include ${this.vhcrecord.naming.QI.name.toLowerCase()}`,
      value: 'includeqi',
      type: 'checkbox',
      checked: true
    }];
    if (settings.lang2enabled) {
      inputs.unshift({
        label: `Print in ${settings.lang2name}`,
        value: 'printsecondlang',
        type: 'checkbox',
        checked: false
      })
    }

    this.helper.createAndOpenAlert('Print vhc record', 'Please choose how you would like to print the record', (v)=>{
      if (v != undefined) {
        this.generatePrintWebsite(settings, !v.includes('printsecondlang'), v.includes('includepre'), v.includes('includempi'), v.includes('includeqi'), v.includes('authonly'))
      }
    }, inputs, 'print', 'cancel', 'wider-alert');
  }

  getStatusName(status) {
    switch (status) {
      case "new":
        return "New";
      case "pre":
        return this.vhcrecord.naming.Pre.name;
      case "mpi":
        return this.vhcrecord.naming.MPI.name;
      case "jp":
        return "Jobs & Parts";
      case "auth":
        return "Authorisation";
      case "qi":
        return this.vhcrecord.naming.QI.name;
      default:
        return "Completed";
    }
  }

  generatePrintWebsite(settings: JSettings, isPrimaryLang = true, includepre, includempi, includeqi, authonly) {
    // HTML PART
    if (this.windowObjectReference != null) {
      this.windowObjectReference.close();
    }

    var winWidth;
    var winHeight;
    if (screen.availHeight > screen.availWidth) {
      winWidth = screen.availWidth
      winHeight = (screen.availWidth * 3508) / 3000
    } else {
      winWidth = (screen.availHeight * 3000) / 3508
      winHeight = screen.availHeight
    }
    this.windowObjectReference = open('', 'VHC Export', `height=${winHeight},width=${winWidth}`);
    const htmlHead = `
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <link rel="stylesheet" href="https://unpkg.com/chota@latest">
      <style>
        body { padding: 20px; } body[dir=rtl] table { text-align: right !important; }
        body table tr td, body table tr th {
          padding: 2px;
          font-size: 14px;
        }
      </style>
      <title>VHC Export</title>
    </head>
    `;
    let htmlBody = "";
    // HTML PART DONE
    
    htmlBody += `<h2>${isPrimaryLang ? this.helper.getDateTime() : _ra(this.helper.getDateTime())}</h2>`
    
    // VEHICLE PART
    htmlBody += `<table class="striped">
      <tbody>
      <tr>
        <td><b>${isPrimaryLang ? "Location Name" : "أسم الفرع"}</b></td>
        <td>${this.currentLocation}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "Service Advisor Name" : "موظف الاستقبال"}</b></td>
        <td>${this.vhcrecord.assignees?.pre?.length > 0 ? this.vhcrecord.assignees.pre[0].name : '-'}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "VIN" : "رقم تسجيل المركبه"}</b></td>
        <td>${this.vhcrecord.vin?this.vhcrecord.vin:'-'}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "Mileage" : "ممشي المركبة"}</b></td>
        <td>${this.vhcrecord.mileage?this.vhcrecord.mileage:'-'}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "Handover" : "تاريخ و وقت التسليم المتوقع"}</b></td>
        <td>${this.vhcrecord.handover?this.vhcrecord.handover:'-'}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "License plate" : "لوحه الترخيص"}</b></td>
        <td>${this.vehicle.licenseplate?this.vehicle.licenseplate:'-'}</td>
      </tr>
      <tr>
        <td><b>${isPrimaryLang ? "Car type" : "نوع السياره"}</b></td>
        <td>${this.vehicle.cartype?this.vehicle.cartype:'-'}</td>
      </tr>`;
    
    // if (this.vehicle.communicatewithowner) {
      htmlBody += `
        <tr>
          <td><b>${isPrimaryLang ? "Owner name" : "اسم الملك"}</b></td>
          <td>${this.vehicle.ownername?this.vehicle.ownername:'-'}</td>
        </tr>
        <tr>
          <td><b>${isPrimaryLang ? "Owner phone" : "رقم جوال المالك"}</b></td>
          <td>${this.vehicle.ownerphone?this.vehicle.ownerphone:'-'}</td>
        </tr>
        <tr>
          <td><b>${isPrimaryLang ? "Owner email" : "البريد الإلكتروني للمالك"}</b></td>
          <td>${this.vehicle.ownermail?this.vehicle.ownermail:'-'}</td>
        </tr>
      `;
    // } else {
      htmlBody += `
        <tr>
          <td><b>${isPrimaryLang ? "Driver name" : "اسم السائق"}</b></td>
          <td>${this.vehicle.drivername?this.vehicle.drivername:'-'}</td>
        </tr>
        <tr>
          <td><b>${isPrimaryLang ? "Driver phone" : "رقم جوال السائق"}</b></td>
          <td>${this.vehicle.driverphone?this.vehicle.driverphone:'-'}</td>
        </tr>
        <tr>
          <td><b>${isPrimaryLang ? "Driver email" : "البريد الإلكتروني السائق"}</b></td>
          <td>${this.vehicle.drivermail?this.vehicle.drivermail:'-'}</td>
        </tr>
      `;
    // }
    
    htmlBody += `</tbody></table>`
    // VEHICLE PART DONE

    // INCLUDE THE STEPS PRE AND MPI
    if (includepre && this.premodules.length > 0) {
      let preblock = '';
      this.premodules.forEach((mod: Vhcmodule) => {
        let htmlrepr = mod.getHtmlVersion(authonly, isPrimaryLang)
        if (htmlrepr != null) {
          preblock += htmlrepr;
        }
      })
      if (preblock !== '') {
        htmlBody += `<br /><h3>${isPrimaryLang ? this.vhcrecord.naming.Pre.name : "الفحص المبداي"}</h3>`;
        htmlBody += preblock;
      }
    }
    if (includempi && this.mpimodules.length > 0) {
      let mpiblock = '';
      this.mpimodules.forEach((mod: Vhcmodule) => {
        let htmlrepr = mod.getHtmlVersion(authonly, isPrimaryLang)
        if (htmlrepr != null) {
          mpiblock += htmlrepr;
        }
      })
      if (mpiblock !== '') {
        htmlBody += `<br /><h3>${isPrimaryLang ? this.vhcrecord.naming.MPI.name : "فحص متعدد النقاط"}</h3>`;
        htmlBody += mpiblock;
      }
    }
    // INCLUDE THE STEPS PRE AND MPI - DONE

    // INCLUDE THE STEP QI
    if (includeqi && this.qimodules.length > 0) {
      let qiblock = '';
      this.qimodules.forEach((mod: Vhcmodule) => {
        let htmlrepr = mod.getHtmlVersion(authonly, isPrimaryLang)
        if (htmlrepr != null) {
          htmlBody += htmlrepr;
        }
      })
      if (qiblock != '') {
        htmlBody += qiblock;
        htmlBody += `<p style="page-break-after: always;">&nbsp;</p><h3>${isPrimaryLang ? 'Other' : "اخري"}</h3>`;
      }
    }
    // INCLUDE THE STEP QI - DONE


    // SIGNATURES

    // SIGNATURES - DONE

    htmlBody += `<p style="page-break-after: always;">&nbsp;</p><h3>${isPrimaryLang ? 'Signatures' : "التوقيع"}</h3>`;
    this.vhcrecord.authobjects.forEach(a => {
      htmlBody += `<strong>${a.datetime}</strong><br /><img src="${a.signature}" /><br />`;
    })

    // PAYMENT
    // let reg = new RegExp('class="price">([0-9]*.[0-9]*)</td>', 'g')
    const regPriceTags = new RegExp(`class=\"price\">(?<price>[0-9]*.[0-9]*)<\/td>`, 'g');
    const matches = htmlBody.match(regPriceTags)
    const priceValues = (matches ? matches : []).map(v => Number.parseFloat(v.substring(14, v.length-5)));
    const totalPrice = (priceValues.length > 0 ? priceValues.reduce((a,b)=>a+b) : 0);
    const totalPriceWithTax = totalPrice * (1+(settings.defaulttax / 100));
    const taxDiff = totalPriceWithTax - totalPrice;
    
    htmlBody += `<h3>${isPrimaryLang ? 'Authorized amount' : "إجمالي المبلغ المصرح به"}</h3>`;
    htmlBody += `<table class="striped" style="border: 1px solid #d2d6dd;">
    <tr>
      <td>${isPrimaryLang ? 'Total excl VAT' : 'الإجمالي بدون الضريبة'}</td>
      <td style="text-align: right;">${isPrimaryLang ? '﷼' : 'SAR'} ${this.helper.tax(totalPrice, 0)}</td>
    </tr>
    <tr>
      <td>${isPrimaryLang ? 'VAT' : 'إجمالي الضريبة'} ${settings.defaulttax}%</td>
      <td style="text-align: right;">${isPrimaryLang ? '﷼' : 'SAR'} ${this.helper.tax(taxDiff, 0)}</td>
    </tr>
    <tr>
      <td>${isPrimaryLang ? 'Total incl VAT' : 'الإجمالي مع الضريبة'}</td>
      <td style="text-align: right;">${isPrimaryLang ? '﷼' : 'SAR'} ${this.helper.tax(totalPriceWithTax, 0)}</td>
    </tr>
    </table>`;
    
    // PAYMENT - DONE

    // WRITE HTML TO REFERENCE
    this.windowObjectReference.document.head.innerHTML = htmlHead;
    this.windowObjectReference.document.body.innerHTML = isPrimaryLang ? htmlBody : this.translateName(htmlBody);
    this.windowObjectReference.document.body.dir = isPrimaryLang ? 'ltr' : 'rtl';
  }

  private translateName(_in: string): string {
    const translateEntries = [
        ['10000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٠,٠٠٠'],
        ['20000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٢٠,٠٠٠'],
        ['30000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٣٠,٠٠٠'],
        ['40000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٤٠,٠٠٠'],
        ['50000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٥٠,٠٠٠'],
        ['60000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٦٠,٠٠٠'],
        ['70000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٧٠,٠٠٠'],
        ['80000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٨٠,٠٠٠'],
        ['90000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم٩٠,٠٠٠'],
        ['100000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٠٠,٠٠٠'],
        ['110000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١١٠,٠٠٠'],
        ['120000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٢٠,٠٠٠'],
        ['130000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٣٠,٠٠٠'],
        ['140000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٤٠,٠٠٠'],
        ['150000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٥٠,٠٠٠'],
        ['160000 KM Periodic Maintenance Package', 'باقة الصيانة الدورية  كم١٦٠,٠٠٠'],
        ['Refresh AC operation (Clima Fresh)', 'معالجه الروائح داخل مقصوره المركبه'],
        ['OZONE Complete Interior Hygiene And Odor treatment', 'تعقيم مكونات دائره التكييف'],
        ['Engine Flush System', 'تنظيف داخلي لدوره المحرك'],
        ['Fuel Injector Cleaner', 'منظف بخاخات الوقود'],
        ['Inspect AC system', 'فحص نظام التكييف'],
        ['Inspect electrical system', 'فحص النظام الكهربائي'],
        ['Inspect brakes', 'فحص الفرامل'],
        ['Inspect steering and suspension', 'فحص التوجيه والتعليق'],
        ['Inspect mechanical system', 'فحص ملاحظات ميكانيكيه'],
        ['AC Promotion', 'عرض كيف مع مازدا'],
        ['Inspect Battery', 'فحص البطارية '],
        ['Summer AC Campaign 2022', 'عروض الصيف للتكيف ٢٠٢٢'],
        ['NANO Ceramic Service Package Big', 'باقة خدمة نانو سيراميك'],
        ['NANO Ceramic Service Package Small', 'باقة خدمة نانو سيراميك'],
    ];
    translateEntries.forEach(v => {
        _in = _in.replace(new RegExp(v[0], 'g'), v[1]);
        console.log(v[0]);
    });
    return _in;
  }

}