import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CrumbtrailComponent } from './components/themeblocks/crumbtrail/crumbtrail.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { HeaderComponent } from './components/themeblocks/header/header.component';
import { FilestructureComponent } from './components/fs/filestructure/filestructure.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSplitModule } from 'angular-split';
import { FiledisplayComponent } from './components/fs/filedisplay/filedisplay.component';
import { TyrebrandsComponent } from './pages/settings/tyrebrands/tyrebrands.component';
import { FormsModule } from '@angular/forms';
import { TyrebrandsDialog } from './pages/settings/tyrebrands/tyrebrands.dialog ';
import { ToolbarComponent } from './components/themeblocks/toolbar/toolbar.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { SharedModule } from './decorators/shared.module';
import { BrakediscpadsComponent } from './pages/settings/brakediscpads/brakediscpads.component';
import { FilebrowserComponent } from './components/fs/filebrowser/filebrowser.component';
import { NewEditDialog } from './services/new-edit.dialog';
import { MarkedPipe } from './pipes/marked.pipe';
import { QuotationlayoutComponent } from './pages/settings/quotationlayout/quotationlayout.component';
import { QuotationlayouteditorComponent } from './pages/settings/quotationlayout/quotationlayouteditor/quotationlayouteditor.component';
import { SafeUrlPipe } from './pipes/safeurl.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VhcblueprintsComponent } from './pages/settings/vhcblueprints/vhcblueprints.component';
import { VhcblueprinteditorComponent } from './pages/settings/vhcblueprints/vhcblueprinteditor/vhcblueprinteditor.component';
import { ModuleoverviewDialog } from './components/moduleoverview/moduleoverview.dialog';
import { QbeditorDialog } from './vhcmodules/questionblock/qbeditor/qbeditor.dialog';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { MaintenancetablesComponent } from './pages/settings/maintenancetables/maintenancetables.component';
import { MaintenanceeditorComponent } from './pages/settings/maintenancetables/maintenanceeditor/maintenanceeditor.component';
import { LengthPipe } from './pipes/length.pipe';
import { VhcrecordComponent } from './pages/vhcrecord/vhcrecord.component';
import { VehicleeditorComponent } from './pages/vehicles/vehicleeditor/vehicleeditor.component';
import { BlueprintchooseDialog } from './pages/vhcrecord/blueprintchoose/blueprintchoose.dialog';
import { MaintenancechooseDialog } from './pages/vhcrecord/maintenancechoose/maintenancechoose.dialog';
import { PrescreenDialog } from './pages/vhcrecord/prescreen/prescreen.dialog';
import { QbanswerDialog } from './vhcmodules/questionblock/qbanswer/qbanswer.dialog';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { ImagedialogDialog } from './components/imagedialog/imagedialog.dialog';
import { SiganswerDialog } from './vhcmodules/signaturemodule/siganswer/siganswer.dialog';
import { MpiscreenDialog } from './pages/vhcrecord/mpiscreen/mpiscreen.dialog';
import { TyreanswerDialog } from './vhcmodules/tyremodule/tyreeanswer/tyreanswer.dialog';
import { TyresizesDialog } from './vhcmodules/tyremodule/tyresizes/tyresizes.dialog';
import { RecommendationEditDialog } from './components/recommendationdialog/recommendationedit.dialog';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { WalkaroundanswerDialog } from './vhcmodules/walkaroundmodule/walkaroundanswer/walkaroundanswer.dialog';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { QiscreenDialog } from './pages/vhcrecord/qiscreen/qiscreen.dialog';
import { AuthscreenDialog } from './pages/vhcrecord/authscreen/authscreen.dialog';
import { AuthviewDialog } from './pages/vhcrecord/authview/authview.dialog';
import { TacDialog } from './components/tacdialog/tacdialog.component';
import { AuthobjviewDialog } from './pages/vhcrecord/authobjview/authobjview.dialog';
import { PackagesComponent } from './pages/settings/packages/packages.component';
import { PartsComponent } from './pages/settings/part/part.component';
import { JobsComponent } from './pages/settings/job/job.component';
import { JobPartscreenDialog } from './pages/vhcrecord/jobpartscreen/jobpartscreen.dialog';
import { JobchooseDialog } from './components/jobchoose/jobchoose.dialog';
import { PartchooseDialog } from './components/partchoose/partchoose.dialog';
import { SelectallDirective } from './selectall.directive';
import { HttpClientModule } from '@angular/common/http';
import { ToceditorComponent } from './pages/settings/toceditor/toceditor.component';
import { BrakeanswerDialog } from './vhcmodules/brakemodule/brakeanswer/brakeanswer.dialog';
import { LocationsComponent } from './pages/locations/locations.component';
import { LocationeditorComponent } from './pages/locations/locationeditor/locationeditor.component';
import { AccountComponent } from './pages/account/account.component';
import { StorageComponent } from './pages/settings/storage/storage.component';
import { Cross_sellComponent } from './pages/settings/cross_sell/cross_sell.component';
import { Cross_sellchooseDialog } from './vhcmodules/cross_sellmodule/cross_sellchoose/cross_sellchoose.dialog';
import { PackagechooseDialog } from './components/packagechoosedialog/packagechoosedialog.component';
import { CsellPckgPricePipe } from './pipes/csell_pckg_price.pipe';
import { JobpartitemComponent } from './components/jobpartitem/jobpartitem.component';
import { FollowupComponent } from './pages/followup/followup.component';
import { MessagetemplatesComponent } from './pages/settings/messagetemplates/messagetemplates.component';
import { FollowupchooseDialog } from './components/followupchoosedialog/followupchoosedialog.component';
import { DatetimechooserDialog } from './components/datetimechooser/datetimechooser.component';
import { DateAwayPipe } from './pipes/date-away.pipe';
import { FollowupeditDialog } from './components/followupeditdialog/followupeditdialog.component';
import { MarkdownDialog } from './components/markdowndialog/markdowndialog.component';
import { DmsItemsPairDialog } from './pages/vhcrecord/jobpartscreen/itemspair/dmsitemspair.dialog';
import { DrawanswerDialog } from './vhcmodules/drawmodule/drawanswer/drawanswer.dialog';
import { BoschAnswerDialog } from './vhcmodules/boschmodule/boschanswer/boschanswer.dialog';
import { OSBSearchDialog } from './pages/home/osbsearch/osbsearch.component';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { LightboxModule } from 'ngx-lightbox';

// for translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient } from '@angular/common/http';
import { BackgroundBrowserDialog } from './vhcmodules/drawmodule/backgroundbrowser/backgroundbrowser.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { UserSearchDialog } from './components/usersearchdialog/usersearch.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { MessageDialog } from './pages/messages/messagedialog.component';
import { VehiclevisualsDialog } from './components/vehiclevisuals/vehiclevisuals.component';
import { IframeWrapperComponent } from './components/iframewrapper/iframewrapper.component';
import { VideorecorderDialog } from './components/videorecorder/videorecorder.component';
import { VideodialogDialog } from './components/videodialog/videodialog.dialog';
import { IqdatepickerComponent } from './components/iqdatepicker/iqdatepicker.component';
import { VarDirective } from './ng-var.directive';
import { GlobalErrorHandler } from './global-error-handler';
import { StoragemeterComponent } from './components/storagemeter/storagemeter.component';
import { GeminisearchDialog } from './pages/home/geminisearch/geminisearch.component';
import { VincouplerComponent } from './pages/settings/vincoupler/vincoupler.component';
import { VincouplereditComponent } from './pages/settings/vincoupler/vincoupleredit/vincoupleredit.component';
import { TaxPipe } from './pipes/tax.pipe';
import { MediaAnswerDialog } from './vhcmodules/mediamodule/mediaanswer/mediaanswer.dialog';
import { CsatanswerDialog } from './vhcmodules/csatmodule/csatanswer/csatanswer.dialog';
import { SpeachService } from './services/speach.service';
import { OSBWizardDialog } from './pages/home/osbwizard/osbwizard.component';

import { DrawerLibModule } from 'drawer-lib';
import { DmsDragDropDialog } from './pages/vhcrecord/jobpartscreen/dmsdragdrop/dmsdragdrop.dialog';

import { IqAudioRecorderComponent } from 'iq-audio-recorder-lib';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        // angular
        AppComponent,
        VarDirective,
        // pipes
        MarkedPipe,
        CsellPckgPricePipe,
        SafeUrlPipe,
        LengthPipe,
        DateAwayPipe,
        TaxPipe,
        // directives
        SelectallDirective,
        // pages
        LoginComponent,
        HomeComponent,
        SettingsComponent,
        TyrebrandsComponent,
        BrakediscpadsComponent,
        QuotationlayoutComponent,
        QuotationlayouteditorComponent,
        VhcblueprintsComponent,
        VhcblueprinteditorComponent,
        VehiclesComponent,
        MaintenancetablesComponent,
        MaintenanceeditorComponent,
        VhcrecordComponent,
        VehicleeditorComponent,
        PackagesComponent,
        PartsComponent,
        JobsComponent,
        ToceditorComponent,
        BrakediscpadsComponent,
        LocationsComponent,
        LocationeditorComponent,
        AccountComponent,
        StorageComponent,
        Cross_sellComponent,
        FollowupComponent,
        MessagetemplatesComponent,
        ReportsComponent,
        MessagesComponent,
        VincouplerComponent,
        // components
        CrumbtrailComponent,
        HeaderComponent,
        FilestructureComponent,
        FiledisplayComponent,
        ToolbarComponent,
        FilebrowserComponent,
        JobpartitemComponent,
        IqdatepickerComponent,
        StoragemeterComponent,
        // dialogs
        ImagedialogDialog,
        QbeditorDialog,
        IframeWrapperComponent,
        ModuleoverviewDialog,
        TyrebrandsDialog,
        NewEditDialog,
        RecommendationEditDialog,
        BlueprintchooseDialog,
        MaintenancechooseDialog,
        PrescreenDialog,
        MpiscreenDialog,
        QbanswerDialog,
        SiganswerDialog,
        TyreanswerDialog,
        MediaAnswerDialog,
        TyresizesDialog,
        WalkaroundanswerDialog,
        QiscreenDialog,
        AuthscreenDialog,
        AuthviewDialog,
        TacDialog,
        AuthobjviewDialog,
        Cross_sellchooseDialog,
        JobPartscreenDialog,
        JobchooseDialog,
        PartchooseDialog,
        BrakeanswerDialog,
        PackagechooseDialog,
        FollowupchooseDialog,
        DatetimechooserDialog,
        FollowupeditDialog,
        MarkdownDialog,
        DmsItemsPairDialog,
        DrawanswerDialog,
        BoschAnswerDialog,
        OSBSearchDialog,
        BackgroundBrowserDialog,
        UserSearchDialog,
        MessageDialog,
        VehiclevisualsDialog,
        VideorecorderDialog,
        VideodialogDialog,
        GeminisearchDialog,
        VincouplereditComponent,
        CsatanswerDialog,
        OSBWizardDialog,
        DmsDragDropDialog
    ],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        // ionic
        IonicModule.forRoot({
            mode: "md",
            spinner: 'dots'
        }),
        // IQ Registry
        IqAudioRecorderComponent,
        // 3rd party
        CdkTreeModule,
        DragDropModule,
        AngularSplitModule,
        PdfViewerModule,
        NgxTippyModule,
        LightboxModule,
        DrawerLibModule,
        // translate
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
            }
        })
    ],
    providers: [
        AndroidPermissions,
        TaxPipe,
        PhotoViewer,
        Camera,
        CameraPreview,
        ScreenOrientation,
        Deeplinks,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(private injector: Injector, private speach: SpeachService) {
    AppModule.injector = injector;
  }
}

// https://angular-split.github.io/
// https://openbase.com/js/ngx-tippy-wrapper
// https://pdfmake.github.io/docs/0.1/getting-started/client-side/
// https://github.com/VadimDez/ng2-pdf-viewer#readme
// https://github.com/Gbuomprisco/ng2-expansion-panels
// https://www.npmjs.com/package/ngx-image-cropper
// https://github.com/naptha/tesseract.js#tesseractjs
// https://material.angular.io/cdk/
// https://github.com/szimek/signature_pad
// https://swiperjs.com/demos
// https://www.npmjs.com/package/lzutf8
// https://www.npmjs.com/package/@bartholomej/ngx-translate-extract
// npm install ngx-lightbox; https://github.com/themyth92/ngx-lightbox