import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-videorecorder',
  templateUrl: './videorecorder.component.html',
  styleUrls: ['./videorecorder.component.scss'],
})
export class VideorecorderDialog implements OnInit, OnDestroy {

  @ViewChild('thevideo') video: ElementRef;
  public stream: MediaStream|undefined;
  public recorder: MediaRecorder|undefined;
  public isrecording = false;
  public menuOpen = false;
  selectedDevice:MediaDeviceInfo = undefined;
  devices:MediaDeviceInfo[] = []

  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService, private popoverController: PopoverController) { }

  ngOnInit() {
    this.initDevices();
    this.initVideo();
  }

  ngOnDestroy(): void {
    if(this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
      this.stream = undefined;
    }
  }

  async initDevices() {
    let _devices = await navigator.mediaDevices?.enumerateDevices();
    if (_devices) {
      _devices = _devices.filter(d => d.kind == 'videoinput')
      _devices.forEach((device) => {
        console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
      });
      this.devices = _devices
    }
  }

  async initVideo() {
    navigator.mediaDevices.getUserMedia({
      video: { width: 1280, height: 720, deviceId: this.selectedDevice?this.selectedDevice.deviceId:undefined },
      audio: true,
    }).then(stm => {
      this.stream = stm;
      this.video.nativeElement.srcObject = this.stream;
      this.video.nativeElement.muted = true;
    }).catch(e => console.error(e));
  }

  switchDevice(device) {
    this.menuOpen = false;
    this.selectedDevice = device;
    if(this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
      this.stream = undefined;
    }
    this.initVideo()
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  startStop() {
    if (this.isrecording && this.recorder) {
      this.recorder.ondataavailable = async (e) => {
        let loader = await this.helper.getLoader()
        loader.title = 'Please wait while the video is uploading';
        await loader.present()
        // var a = document.createElement('a')
        // a.download = ['video_', (new Date() + '').slice(4, 28), '.webm'].join('');
        // a.href = URL.createObjectURL(e.data);
        // a.textContent = a.download;
        // a.click();
        let path = ['uservids/video_', this.helper.getUUID(), '.webm'].join('');
        this.supa.supabase.storage.from("internaldesign").upload(path, e.data)
        loader.dismiss();
        console.log('Done!')
        this.modalController.dismiss({path: path});
      };
      this.isrecording = false;
      this.recorder.stop();
    } else if (this.stream && !this.isrecording) {
      // check mimetypes {
      let options = {};
      if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        options = {mimeType: 'video/webm; codecs=vp9'};
      } else  if (MediaRecorder.isTypeSupported('video/webm')) {
        options = {mimeType: 'video/webm'};
      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        options = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
      } else {
        console.error("no suitable mimetype found for this device");
        this.helper.notify('No suitable camera found')
        return
      }
      // } end mime types check
      this.recorder = new MediaRecorder(this.stream, options)
      this.recorder.start();
      this.isrecording = true;
      console.log('Start recording')
    }
  }

}